class MangoQueryMaker {
  constructor(arr) {
    this.conditions = arr
      .map((item) => {
        return this.makeConditions(item)
      })
      .filter((obj) => {
        return obj != undefined
      })
  }

  get selector() {
    return this.conditions.length
      ? {
          $and: this.conditions,
        }
      : undefined
  }

  get empty() {
    return !this.conditions
      .map((condition) => {
        return condition['$or'].length
      })
      .find((item) => item)
  }

  makeConditions({ selected, fieldName, conditionOperator, combinationOperator, value }) {
    selected = selected.filter((item) => {
      if (item.value === '') {
        return false
      } else return true
    })

    let result
    if (!selected.length) {
      result = { $or: [] }
    } else {
      let conditions = selected.map((item) => {
        let operator = conditionOperator
        let operand = item[value]
        let condition = { [operator]: operand }
        return { [fieldName]: condition }
      })

      let operator = combinationOperator
      result = { [operator]: conditions }
    }
    return result
  }
}

let getSelected = async (component) => {
  let { presentationCollection, modelCollection } = await component.fetchStrategy({
    datasource: await component.itemsdatasource,
    component: component,
  })

  let presentations = []
  for await (let presentationRef of presentationCollection) {
    presentations.push(presentationRef.data())
  }

  let presentationOrderModelIds = presentations.map((item) => item.model_id)

  let models = []
  for await (let modelRef of modelCollection) {
    let model = modelRef.data()
    let i = presentationOrderModelIds.findIndex((item) => item == model.id)
    model.checked = presentations[i].selected
    models.push(model)
  }

  return models.filter((model) => model.checked == true)
}

let map = new Map([
  [
    'selector-main-menu',
    (app) => () => ({
      changed: async ({ e, component }) => {
        let selected = await getSelected(component)
        component.query = {
          selected: selected,
          fieldName: 'main-menu',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-filter',
    (app) => () => ({
      changed: async ({ e, component }) => {
        let selected = await getSelected(component)

        component.query = {
          selected: selected,
          fieldName: 'filter',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-balcony',
    (app) => () => ({
      changed: async ({ e, component }) => {
        let selected = await getSelected(component)
        component.query = {
          selected: selected,
          fieldName: 'balcony',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-elevator',
    (app) => () => ({
      changed: async ({ e, component }) => {
        let selected = await getSelected(component)
        component.query = {
          selected: selected,
          fieldName: 'elevator',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-new-production',
    (app) => () => ({
      changed: async ({ e, component }) => {
        let selected = await getSelected(component)
        component.query = {
          selected: selected,
          fieldName: 'new_production',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-congregation',
    (app) => () => ({
      changed: ({ e, component }) => {
        component.query = {
          selected: e.detail.selecteditems,
          fieldName: 'congregation',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-housing-tenure',
    (app) => () => ({
      changed: ({ e, component }) => {
        component.query = {
          selected: e.detail.selected,
          fieldName: 'housing_tenure',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-maximum-number-of-rooms',
    (app) => () => ({
      changed: ({ e, component }) => {
        component.query = {
          selected: [e.detail],
          fieldName: 'number_of_rooms',
          conditionOperator: '$lte',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-minimum-number-of-rooms',
    (app) => () => ({
      changed: ({ e, component }) => {
        component.query = {
          selected: [e.detail],
          fieldName: 'number_of_rooms',
          conditionOperator: '$gte',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
  [
    'selector-type-of-housing',
    (app) => () => ({
      changed: ({ e, component }) => {
        component.query = {
          selected: e.detail.selected,
          fieldName: 'type_of_housing',
          conditionOperator: '$eq',
          combinationOperator: '$or',
          value: 'value',
        }
        return component.query
      },
    }),
  ],
])

export { map as default }
