const assignProperties = ({ from, to }) => {
  for (let key in from) {
    if (from[key] === undefined) {
      delete from[key]
    }
  }
  Object.assign(to, from)
}

const datapropertyMixin = ({ imports }) => () => (superClass) => {
  let { html, R, rxHelpers } = imports
  return class DatapropertyMixin extends superClass {
    firstUpdated() {
      super.firstUpdated()

      //Data is an object that can contain all properties of the component
      rxHelpers
        .combineLatest([this.data$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(() => {
          assignProperties({ from: this.data, to: this })
          this.requestUpdate()
        })
    }
  }
}

export { datapropertyMixin as default }
