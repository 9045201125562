let map = new Map([
  [
    'slice',
    (app) =>
      () =>
      () =>
      ({ datasource }) => {
        let modelCollection = datasource.store.slice(datasource.slice).collection(datasource.models)
        let presentationCollection = datasource.store.slice(datasource.slice).collection(datasource.presentations)

        return { presentationCollection, modelCollection, presentationStream: presentationCollection.state }
      },
  ],
])

export { map as default }
