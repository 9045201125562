// import xAlert from '../themes/x-alert-theme'
// import xAlertgroup from '../themes/x-alertgroup-theme'
// import xApp from '../themes/x-app-theme'
// import xAuthentication from '../themes/x-authentication-theme'
// import xBadge from '../themes/x-badge-theme'
// import xBreadcrumbs from '../../components/inprogress/x-breadcrumbs/x-breadcrumbs-theme'
// import xButton from '../../components/x-selectoritems/x-button/x-button-theme'
// import xCheckbox from '../../components/x-selectoritems/x-checkbox/x-checkbox-theme'
// import xClose from '../../components/inprogress/x-close/x-close-theme'
// import xCollapse from '../../components/x-treebox-multi/x-collapse/x-collapse-theme'
// import xDatacell from '../themes/x-datacell-theme'
// import xDatatable from '../themes/x-datatable-theme'
// import xDropdown from '../../components/inprogress/x-dropdown/x-dropdown-theme'
// import xFilterComposer from '../themes/x-filter-composer-theme'
// import xFilterSelector from '../themes/x-filter-selector-theme'
// import xForm from '../themes/x-form-theme'
// import xFormbox from '../../components/x-formbox/x-formbox-theme'
// import xFormboxHint from '../../components/x-formbox/x-formbox-hint/x-formbox-hint-theme'
// import xFormsbox from '../themes/x-formsbox-theme'
// import xFormspage from '../themes/x-formspage-theme'
// import xFormspageInfo from '../themes/x-formspage-info-theme'
import xGap from '../themes/x-gap-theme'
// import xHeader from '../../components/inprogress/x-header/x-header-theme'
import xIcon from '../themes/x-icon-theme'
import xImage from '../themes/x-image-theme'
// import xInput from '../themes/x-input-theme'
// import xInputFix from '../../components/inprogress/x-input-fix/x-input-fix-theme'
// import xMap from '../themes/x-map-theme'
// import xMedia from '../themes/x-media-theme'
// import xMediagroup from '../themes/x-mediagroup-theme'
// import xModal from '../../components/inprogress/x-modal/x-modal-theme'
import xNavbar from '../themes/x-navbar-theme'
import xNavbarItem from '../themes/x-navbar-item-theme'
// import xNumberfield from '../../components/inprogress/x-numberfield/x-numberfield-theme'
// import xPagination from '../themes/x-pagination-theme'
// import xPaginationgroup from '../themes/x-paginationgroup-theme'
// import xQueryMaker from '../themes/x-query-maker-theme'
// import xSectionOverlay from '../themes/x-section-overlay-theme'
// import xSectionOverlayGroup from '../themes/x-section-overlay-group-theme'
// import xRadiobutton from '../../components/x-selectoritems/x-radiobutton/x-radiobutton-theme'
// import xRange from '../themes/x-range-theme'
// import xRate from '../themes/x-rate-theme'
// import xRateItem from '../themes/x-rate-item-theme'
// import xRategroup from '../themes/x-rategroup-theme'
// import xSelect from '../themes/x-select-theme'
// import xSelector from '../themes/x-selector-theme'
// import xSidenav from '../themes/x-sidenav-theme'
// import xSwitch from '../../components/x-selectoritems/x-switch/x-switch-theme'
// import xTab from '../../components/x-selectoritems/x-tab/x-tab-theme'
// import xTag from '../themes/x-tag-theme'
// import xTaggroup from '../themes/x-taggroup-theme'
import xText from '../themes/x-text-theme'
import xTextgroup from '../themes/x-textgroup-theme'
// import xTextarea from '../../components/x-inputitems/x-textarea/x-textarea-theme'
// import xTextfield from '../../components/x-inputitems/x-textfield/x-textfield-theme'
// import xToast from '../themes/x-toast-theme'
// import xToastgroup from '../themes/x-toastgroup-theme'
// import xToggle from '../../components/x-selectoritems/x-toggle/x-toggle-theme'
// import xTooltip from '../themes/x-tooltip-theme'
// import xTreebox from '../themes/x-treebox-theme'
// import xTreeboxMulti from '../../components/x-treebox-multi/x-treebox-multi-theme'
// import xTreeboxgroup from '../themes/x-treeboxgroup-theme'
import xVideo from '../themes/x-video-theme'

let map = new Map([
  // ['x-app', xApp],
  // ['x-alert', xAlert],
  // ['x-alertgroup', xAlertgroup],
  // ['x-authentication', xAuthentication],
  // ['x-badge', xBadge],
  // ['x-breadcrumbs', xBreadcrumbs],
  // ['x-button', xButton],
  // ['x-checkbox', xCheckbox],
  // ['x-close', xClose],
  // ['x-collapse', xCollapse],
  // ['x-datacell', xDatacell],
  // ['x-datatable', xDatatable],
  // ['x-dropdown', xDropdown],
  // ['x-filter-composer', xFilterComposer],
  // ['x-filter-selector', xFilterSelector],
  // ['x-form', xForm],
  // ['x-formbox', xFormbox],
  // ['x-formbox-hint', xFormboxHint],
  // ['x-formsbox', xFormsbox],
  // ['x-formspage', xFormspage],
  // ['x-formspage-info', xFormspageInfo],
  ['x-gap', xGap],
  // ['x-header', xHeader],
  ['x-icon', xIcon],
  ['x-image', xImage],
  // ['x-input', xInput],
  // ['x-input-fix', xInputFix],
  // ['x-map', xMap],
  // ['x-media', xMedia],
  // ['x-mediagroup', xMediagroup],
  // ['x-modal', xModal],
  ['x-navbar', xNavbar],
  ['x-navbar-item', xNavbarItem],
  // ['x-numberfield', xNumberfield],
  // ['x-pagination', xPagination],
  // ['x-paginationgroup', xPaginationgroup],
  // ['x-query-maker', xQueryMaker],
  // ['x-radiobutton', xRadiobutton],
  // ['x-range', xRange],
  // ['x-rate', xRate],
  // ['x-rategroup', xRategroup],
  // ['x-rate-item', xRateItem],
  // ['x-section-overlay', xSectionOverlay],
  // ['x-section-overlay-group', xSectionOverlayGroup],
  // ['x-select', xSelect],
  // ['x-selector', xSelector],
  // ['x-sidenav', xSidenav],
  // ['x-switch', xSwitch],
  // ['x-tab', xTab],
  // ['x-tag', xTag],
  // ['x-taggroup', xTaggroup],
  ['x-text', xText],
  ['x-textgroup', xTextgroup],
  // ['x-textfield', xTextfield],
  // ['x-textarea', xTextarea],
  // ['x-toast', xToast],
  // ['x-toastgroup', xToastgroup],
  // ['x-toggle', xToggle],
  // ['x-tooltip', xTooltip],
  // ['x-treebox', xTreebox],
  // ['x-treebox-multi', xTreeboxMulti],
  // ['x-treeboxgroup', xTreeboxgroup],
  ['x-video', xVideo],
])

export { map as default }
