let map = new Map([
  [
    'badge',
    () =>
      ({ html }) => ({
        slotName: 'badge',
        css: () => {
          return html`
            <style>
              slot[name='badge']::slotted(*) {
                position: absolute;
                top: -12px;
                right: -12px;
              }
            </style>
          `
        },
      }),
  ],
  [
    'dropdown',
    () =>
      ({ html }) => ({
        slotName: 'dropdown',
        handler: (e) => this.slotchangeDropdownHandler(e),
        css: () => {
          return html`
            <style>
              slot[name='dropdown']::slotted(*) {
                ${this.dropdownPosition}
              }
            </style>
          `
        },
      }),
  ],
  [
    'tooltip',
    () =>
      ({ html }) => ({
        slotName: 'tooltip',
        handler: (e) => this.slotchangeTooltipHandler(e),
        css: () => {
          return html`
            <style>
              slot[name='tooltip']::slotted(*) {
                ${this.tooltipPosition}
              }
            </style>
          `
        },
      }),
  ],
])

export { map as default }
