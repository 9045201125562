let map = new Map([
  [
    'checkbox',
    () => () => ({
      changed: ({ items, i }) => {
        items.forEach((node, index) => {
          if (i == index) {
            node.removeAttribute('indeterminate')
            if (node.checked) {
              node.removeAttribute('checked')
            } else {
              node.setAttribute('checked', '')
            }
          }
        })
      },
    }),
  ],
  [
    'radio',
    () => () => ({
      changed: ({ items, i }) => {
        items.forEach((node, index) => {
          if (i == index) {
            node.setAttribute('checked', '')
          } else {
            node.removeAttribute('checked')
          }
        })
      },
    }),
  ],
])

export { map as default }
