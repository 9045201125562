import flagiconcss from '../icons/flagiconcss'
import fontawesome from '../icons/fontawesome'
import ionicons from '../icons/ionicons'
import materialdesign from '../icons/materialdesign'

let map = new Map([
  ['flagiconcss', flagiconcss],
  ['fontawesome', fontawesome],
  ['ionicons', ionicons],
  ['materialdesign', materialdesign],
])

export { map as default }
