export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement } = imports
  let props = () => []

  class BfAboutPage extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['X-TEXT', 'X-TEXTGROUP']
      this.layout = 'flex-column'
    }

    get themes() {
      return componentThemes
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* :host {
            --header-height: 100px;
            --footer-height: 288px;
          }
          .container {
            display: flex;
            flex-direction: column;
          }

          .imagebox {
            flex: 0 0 auto;
          }

          .main {
            flex: 0 0 auto;
            display: grid;
            grid-template-columns: minmax(102px, 1fr) 141px 1455px minmax(102px, 1fr);
            grid-template-rows: auto;
            grid-template-areas: '. . paragraphs .';
            padding-bottom: 100px;
            padding-top: 80px;
          }

          .paragraphs {
            grid-area: paragraphs;
            padding-left: 70px;
            border-left: 1px solid black;
            max-width: 947px;
          }

          @media screen and (max-width: 1800px) {
            .main {
              grid-template-columns: minmax(40px, 102px) 141px 1455px minmax(40px, 102px);
            }
          }

          @media screen and (max-width: 1676px) {
            .main {
              grid-template-columns: 40px 141px minmax(947px, 1455px) 40px;
            }
          }

          @media screen and (max-width: 1676px) {
            .main {
              grid-template-columns: 40px 141px minmax(1158px, 1455px) 40px;
            }
          }

          @media screen and (max-width: 1379px) {
            .main {
              grid-template-columns: 40px minmax(0px, 141px) minmax(1017px, 1158px) 40px;
            }
          }

          @media screen and (max-width: 1097px) {
            .main {
              grid-template-columns: 40px 0px minmax(860px, 944px) 110px;
            }
          }

          @media screen and (max-width: 1010px) {
            .main {
              grid-template-columns: 40px 0px 820px minmax(80px, 150px);
            }

            .paragraphs {
              padding-left: 40px;
            }
          }

          @media screen and (max-width: 940px) {
          } */

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }
            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 40px 0px 1fr 0px 40px;
              grid-template-rows: auto;
              grid-template-areas: '. . paragraphs . .';
              padding-bottom: 100px;
              padding-top: 80px;
            }

            .paragraphs {
              flex: 0 0 auto;
              grid-area: paragraphs;
              padding-left: 40px;
              border-left: 1px solid black;
              max-width: 947px;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }
            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 60px 0px 1fr 0px 60px;
              grid-template-rows: auto;
              grid-template-areas: '. . paragraphs . .';
              padding-bottom: 100px;
              padding-top: 80px;
            }

            .paragraphs {
              flex: 0 0 auto;
              grid-area: paragraphs;
              padding-left: 40px;
              border-left: 1px solid black;
              max-width: 947px;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }
            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . paragraphs . .';
              padding-bottom: 100px;
              padding-top: 80px;
            }

            .paragraphs {
              flex: 0 0 auto;
              grid-area: paragraphs;
              padding-left: 40px;
              border-left: 1px solid black;
              max-width: 947px;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }
            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . paragraphs . .';
              padding-bottom: 100px;
              padding-top: 80px;
            }

            .paragraphs {
              flex: 0 0 auto;
              grid-area: paragraphs;
              padding-left: 40px;
              border-left: 1px solid black;
              /* max-width: 947px; */
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }
            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
              max-width: 1366px;
              align-self: center;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 1fr 80px 0px 1207px 0px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . . paragraphs . . .';
              padding-bottom: 100px;
              padding-top: 80px;
            }

            .paragraphs {
              flex: 0 0 auto;
              grid-area: paragraphs;
              padding-left: 40px;
              border-left: 1px solid black;
              /* max-width: 947px; */
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <x-image class=${classMap({ imagebox: true })} .data=${this.backgroundimage} pattern="proportional-height"></x-image>
          <div class=${classMap({ main: true })}>
            <x-textgroup class=${classMap({ paragraphs: true })} theme="backflip-about">
              <x-text theme="backflip-body-large">
                Dylan Williams set up BACKFLIP to develop and deliver ambitious, engaging documentary works, made for an international audience. Over the last
                15 years he has produced and directed award-winning work for cinema and television, telling intimate stories to international audiences. He has
                made films for SVT, Netflix, BBC, ARTE and been shown in over 70 countries worldwide and won awards all over the world including Best
                Documentary at the Art Doc Fest, Moscow, the Prix Italia and the Audience Award at Silverdocs in Washington.
              </x-text>
              <x-text theme="backflip-body-large">
                Whether sharing his intimate relationship with his father told through his father’s lifelong love for his Choir (MEN WHO SING); his own mid-life
                crisis with (MEN WHO SWIM) or tackling global issues such as the destruction of the Borneo Rainforest (THE BORNEO CASE) Dylan is always
                dedicated to come as close as he can to the truth in order to tell the most compelling story that he can.
              </x-text>
            </x-textgroup>
          </div>
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-about-page`, BfAboutPage)
}

// <div class=${classMap({ imagebox: true })}><img class=${classMap({ image: true })} src=${this.image} /></div>
