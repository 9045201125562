export default (imports) => {
  let { baseMixin, componentThemes, html, LitElement } = imports
  let props = () => [
    { propKey: 'checked', propValue: { type: Boolean } },
    { propKey: 'disabled', propValue: { type: Boolean } },
    { propKey: 'imageUrl', propValue: { type: String } },
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'theme', propValue: { type: String } },
    { propKey: 'type', propValue: { type: String } },
  ]

  class BfFilmsItemgroup extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['BF-FILMS-ITEM']
      this.checked = false
      this.disabled = false
      this.label = 'No label'
      this.type = 'click'
      this.displayNone = false
    }

    get themes() {
      return componentThemes
    }

    handleClose(e) {
      e.stopPropagation()

      this.displayNone = true
      let event = new CustomEvent('remove-element-event', {})
      this.dispatchEvent(event)
      this.requestUpdate()
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()

      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })

      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      this.nodes.forEach((node, i) => {
        if (node.unsubscribe) {
          node.unsubscribe()
        }
        node.unsubscribe = node.eventHandlerRegistrar(node, 'container-click-event', (e) => {
          this.handleItemChanged(this, e, i)
        })
      })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}

        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: grid;
              grid-column-gap: var(--theme-container-grid-column-gap, 10px);
              grid-row-gap: var(--theme-container-grid-row-gap, 48px);
              grid-template-columns: repeat(${this.columns - 3}, 1fr);
              column-gap: 60px;
              row-gap: 60px;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: grid;
              grid-column-gap: var(--theme-container-grid-column-gap, 10px);
              grid-row-gap: var(--theme-container-grid-row-gap, 48px);
              grid-template-columns: repeat(${this.columns - 2}, 1fr);
              column-gap: 60px;
              row-gap: 60px;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: grid;
              grid-column-gap: var(--theme-container-grid-column-gap, 10px);
              grid-row-gap: var(--theme-container-grid-row-gap, 48px);
              grid-template-columns: repeat(${this.columns - 2}, 1fr);
              column-gap: 60px;
              row-gap: 60px;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: grid;
              grid-column-gap: var(--theme-container-grid-column-gap, 10px);
              grid-row-gap: var(--theme-container-grid-row-gap, 48px);
              grid-template-columns: repeat(${this.columns - 1}, 1fr);
              column-gap: 60px;
              row-gap: 60px;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: grid;
              grid-column-gap: var(--theme-container-grid-column-gap, 10px);
              grid-row-gap: var(--theme-container-grid-row-gap, 48px);
              grid-template-columns: repeat(${this.columns - 0}, 1fr);
              column-gap: 60px;
              row-gap: 60px;
            }
          }
        </style>
        <div class="container">
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
        </div>
      `
    }
  }

  customElements.define(`bf-films-itemgroup`, BfFilmsItemgroup)
}
