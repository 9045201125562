let renderAlertItem = ({ component, fetch, html, render, theme, themes, direction, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    console.log('IN RENDER')
    let presentations = []
    console.log('presentationCollection', presentationCollection)
    for await (let presentationRef of presentationCollection) {
      console.log('presentationRef', presentationRef)
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.themes = themes.get(theme).module()
      model.theme = presentations[i].theme
      model.direction = direction
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderSelectorItem = ({ component, fetch, html, render, theme, themes, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    let presentations = []
    for await (let presentationRef of presentationCollection) {
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.themes = themes.get(theme).module()
      model.theme = presentations[i].theme
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderNavbarItem = ({ component, fetch, html, render, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    let presentations = []
    for await (let presentationRef of presentationCollection) {
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.theme = presentations[i].theme
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderTree = ({ component, fetch, html, render, template }) => {
  let templates = [template(fetch)]
  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let renderTreegroup = ({ component, fetch, html, render, template }) => {
  let templates = fetch.map((data) => {
    if (data.items && data.items.length) {
      return template.multi(data)
    } else {
      return template.single(data)
    }
  })

  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let renderTreebox = ({ component, fetch, html, render, template }) => {
  let templates = [template(fetch)]
  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let map = new Map([
  [
    'main-menu-button',
    () =>
      ({ html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let { presentationCollection, modelCollection, presentationStream } = fetch

        presentationStream.subscribe(async () => {
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let data = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == data.id)
            data.checked = presentations[i].selected
            data.label = { label: voca.upperCase(data.label), theme: 'regular-menuitem' }
            data.dispatchStrategyName = 'button'
            data.theme = 'main-menu'
            templates[i] = html`<x-button .data=${data}></x-button>`
          }

          render(templates, component)
          component.requestUpdate()
        })
      },
  ],
  [
    'bf-menu-item',
    () =>
      ({ html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let template = (data) => {
          data.labelTheme = 'backflip-menu'
          return html`<x-navbar-item .data=${data} theme=${data.theme} dispatchStrategyName="navbar-item"></x-navbar-item>`
        }

        let { presentationCollection, modelCollection, presentationStream } = fetch
        let labelFnMap = new Map([
          ['capitalize', (label) => voca.capitalize(label)],
          ['uppercase', (label) => voca.upperCase(label)],
        ])

        presentationStream.subscribe(async () => {
          // console.log('NEW MENU RENDER')
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let model = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == model.id)
            model.checked = presentations[i].selected
            model.theme = 'backflip-menu'
            model.label = labelFnMap.get('uppercase')(model.label)
            templates[i] = template(model)
          }

          render(html``, component)
          component.requestUpdate()
          component.updateComplete.then(() => {
            render(templates, component)
            component.requestUpdate()
          })
        })
      },
  ],
  [
    'bf-footer-item',
    () =>
      ({ classMap, html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let template = (data) => {
          return html`<div class=${classMap({ logo: true })}><x-text class="logo" theme="backflip-logo">BACKFLIP MEDIA</x-text></div>
            <x-gap theme="h-8"></x-gap>
            <x-textgroup theme="backflip-footer-contact">
              <x-text theme="backflip-footer-bold">CONTACT</x-text>
              <x-textgroup theme="backflip-footer-contact-info">
                <x-text theme="backflip-footer">Nybohovsbacken 23 117 63 / Stockholm</x-text>
                <x-text theme="backflip-footer">dylan@backflipmedia.se</x-text>
              </x-textgroup>
            </x-textgroup>
          </div>`
        }

        let { presentationCollection, modelCollection, presentationStream } = fetch
        let labelFnMap = new Map([
          ['capitalize', (label) => voca.capitalize(label)],
          ['uppercase', (label) => voca.upperCase(label)],
        ])

        presentationStream.subscribe(async () => {
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let model = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == model.id)
            model.checked = presentations[i].selected
            model.theme = presentations[i].theme
            model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
            templates[i] = template(model)
          }

          render(html``, component)
          component.requestUpdate()
          component.updateComplete.then(() => {
            render(templates, component)
            component.requestUpdate()
          })
        })
      },
  ],
  [
    'bf-news-item',
    () =>
      ({ classMap, html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let template = (data) => {
          return html`<bf-news-item .data=${data}></bf-news-item>`
        }

        let { presentationCollection, modelCollection, presentationStream } = fetch
        let labelFnMap = new Map([
          ['capitalize', (label) => voca.capitalize(label)],
          ['uppercase', (label) => voca.upperCase(label)],
        ])

        presentationStream.subscribe(async () => {
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let model = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == model.id)
            model.checked = presentations[i].selected
            model.theme = presentations[i].theme
            model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
            templates[i] = template(model)
          }

          render(html``, component)
          component.requestUpdate()
          component.updateComplete.then(() => {
            render(templates, component)
            component.requestUpdate()
          })
        })
      },
  ],
  [
    'bf-films-item',
    () =>
      ({ classMap, html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let template = (data) => {
          return html`<bf-films-item layout="flex-column" .data=${data} dispatchStrategyName="bf-films-item"></bf-films-item>`
        }

        let { presentationCollection, modelCollection, presentationStream } = fetch
        let labelFnMap = new Map([
          ['capitalize', (label) => voca.capitalize(label)],
          ['uppercase', (label) => voca.upperCase(label)],
        ])

        presentationStream.subscribe(async () => {
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let model = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == model.id)
            model.checked = presentations[i].selected
            model.theme = presentations[i].theme
            model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
            if (model.status.includes('ready')) {
              templates[i] = template(model)
            }
          }

          render(html``, component)
          component.requestUpdate()
          component.updateComplete.then(() => {
            render(templates, component)
            component.requestUpdate()
          })
        })
      },
  ],

  [
    'bf-in-production-item',
    () =>
      ({ classMap, html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        let template = (data) => {
          return html`<bf-inproduction-item layout="flex-column" .data=${data}></bf-inproduction-item>`
        }

        let { presentationCollection, modelCollection, presentationStream } = fetch
        let labelFnMap = new Map([
          ['capitalize', (label) => voca.capitalize(label)],
          ['uppercase', (label) => voca.upperCase(label)],
        ])

        presentationStream.subscribe(async () => {
          let presentations = []
          for await (let presentationRef of presentationCollection) {
            presentations.push(presentationRef.data())
          }

          let presentationOrderModelIds = presentations.map((item) => item.model_id)
          let templates = new Array(presentations.length)

          for await (let modelRef of modelCollection) {
            let model = modelRef.data()
            let i = presentationOrderModelIds.findIndex((item) => item == model.id)
            model.checked = presentations[i].selected
            model.theme = presentations[i].theme
            model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
            if (model.status.includes('in-production')) {
              templates[i] = template(model)
            }
          }

          render(html``, component)
          component.requestUpdate()
          component.updateComplete.then(() => {
            render(templates, component)
            component.requestUpdate()
          })
        })
      },
  ],
])

export { map as default }
