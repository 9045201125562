export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, rxHelpers, transactionalLocalStore, voca } = imports
  let props = () => [
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'video', propValue: { type: String } },
    { propKey: 'pattern', propValue: { type: String } },
    { propKey: 'provider', propValue: { type: String } },
    { propKey: 'autoplay', propValue: { type: Boolean } },
    { propKey: 'backgroundimage', propValue: { type: Object } },
    { propKey: 'backgroundvideo', propValue: { type: Object } },
  ]

  class BfFilmPage extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['X-TEXT', 'X-TEXTGROUP']
      this.layout = 'flex-column'
      this.news = []
      this.paragraphs = []
    }

    get themes() {
      return componentThemes
    }

    firstUpdated() {
      super.firstUpdated()
      console.log('WLH', window.location.hash)
      console.log('HASH', this.hash)
      transactionalLocalStore
        .slice('bfFilms')
        .collection('models')
        .where('id', '==', this.hash[0])
        .get()
        .then((doc) => {
          this.data = doc.map((doc) => doc.data())[0]
        })
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .background-videobox {
              flex: 0 0 auto;
              align-self: center;
              width: 100%;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 40px 0px 1fr 0px 40px;
              grid-template-rows: auto;
              grid-template-areas: '. . body . .';
              padding-top: ${!this.backgroundvideo && !this.backgroundimage ? '76px' : 0};
            }

            .body {
              grid-area: body;
              padding-top: 40px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
            }

            .headline {
              flex: 0 0 auto;
            }

            .block {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              column-gap: 40px;
              row-gap: 40px;
            }

            .videobox {
              flex: 0 0 auto;
            }

            .content {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              row-gap: 40px;
              max-width: 627px;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .background-videobox {
              flex: 0 0 auto;
              /* max-width: 1366px; */
              align-self: center;
              width: 100%;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 60px 0px 1fr 0px 60px;
              grid-template-rows: auto;
              grid-template-areas: '. . body . .';
              padding-top: ${!this.backgroundvideo && !this.backgroundimage ? '76px' : 0};
            }

            .body {
              grid-area: body;
              padding-top: 40px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
            }

            .headline {
              flex: 0 0 auto;
            }

            .block {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              column-gap: 40px;
              row-gap: 40px;
            }

            .videobox {
              flex: 0 0 auto;
              max-width: 568px;
            }

            .content {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              row-gap: 40px;
              max-width: 627px;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .background-videobox {
              flex: 0 0 auto;
              /* max-width: 1366px; */
              align-self: center;
              width: 100%;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . body . .';
              padding-top: ${!this.backgroundvideo && !this.backgroundimage ? '76px' : 0};
            }

            .body {
              grid-area: body;
              padding-top: 40px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
            }

            .headline {
              flex: 0 0 auto;
            }

            .block {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              column-gap: 40px;
              row-gap: 40px;
            }

            .videobox {
              flex: 0 0 auto;
              max-width: 568px;
            }

            .content {
              flex: 0 0 auto;
              display: flex;
              flex-direction: column;
              row-gap: 40px;
              max-width: 627px;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .background-videobox {
              flex: 0 0 auto;
              align-self: center;
              width: 100%;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . body . .';
              padding-top: ${!this.backgroundvideo && !this.backgroundimage ? '176px' : 0};
            }

            .body {
              grid-area: body;
              padding-top: 40px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
            }

            .headline {
              flex: 0 0 auto;
            }

            .block {
              flex: 1 1 auto;
              display: flex;
              flex-direction: row;
              column-gap: 40px;
              row-gap: 40px;
            }

            .videobox {
              flex: 1 1 568px;
            }

            .content {
              flex: 1 1 598px;
              display: flex;
              flex-direction: column;
              row-gap: 40px;
              max-width: 627px;
            }

            /* .video {
              width: 568px;
              height: 344px;
            } */
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
            }

            .imagebox {
              flex: 0 0 auto;
              max-width: 1366px;
              align-self: center;
            }

            .background-videobox {
              flex: 0 0 auto;
              align-self: center;
              width: 100%;
            }

            .main {
              flex: 0 0 auto;
              display: grid;
              grid-template-columns: 1fr 80px 0px 1207px 0px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . . body . . .';
              padding-top: ${!this.backgroundvideo && !this.backgroundimage ? '176px' : 0};
            }

            .body {
              grid-area: body;
              padding-top: 40px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
            }

            .block {
              flex: 1 1 auto;
              display: flex;
              flex-direction: row;
              column-gap: 40px;
              row-gap: 40px;
            }

            .videobox {
              flex: 1 1 568px;
            }

            .content {
              flex: 1 1 598px;
              display: flex;
              flex-direction: column;
              row-gap: 40px;
              max-width: 627px;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <x-video class=${classMap({ 'background-videobox': true })} .data=${this.backgroundvideo} theme="backflip-film-background"></x-video>
          <div class="main">
            <div class="body">
              <x-text class="headline" theme="backflip-header">${voca.upperCase(this.label)}</x-text>
              <div class="block">
                ${this.video ? html`<x-video .data=${this.video} class=${classMap({ videobox: true })} theme="backflip-film"></x-video>` : html``}
                <div class="content">
                  <bf-description .data=${{ description: this.description }} theme="backflip-film-description"></bf-description>
                  <x-textgroup theme="backflip-about">
                    ${this.paragraphs.map((paragraph) => {
                      return html` <x-text theme="backflip-body"> ${paragraph} </x-text>`
                    })}
                  </x-textgroup>
                </div>
              </div>
            </div>
          </div>
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-film-page`, BfFilmPage)
}
//  <div class=${classMap({ videobox: true })}>
//       <iframe class=${classMap({ video: true })} src=${this.video}> </iframe>
//     </div>
// <img style="width: 100%; object-fit: cover;" src=${this.video} class="imageposter" alt="..." />
// <div class=${classMap({ imagebox: true })}><img class=${classMap({ image: true })} src=${this.backgroundimage} /></div>
