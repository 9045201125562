//MESSY. FIX SLOTSTRATEGIES
const slotMixin = ({ imports }) => () => (superClass) => {
  let { check, html, R, rxHelpers } = imports
  return class SlotMixin extends superClass {
    constructor() {
      super()
      this.renderSlotsCSS = () => {}
      this.renderSlotsHTML = () => {}
    }

    getSlottedNodes(nodeName, e) {
      let nodes

      nodes = e.target.assignedNodes().filter((node) => {
        if (check.string(nodeName)) {
          if (nodeName == '*') {
            return node.nodeName != '#text'
          } else {
            return node.nodeName == nodeName
          }
        }

        if (check.nonEmptyArray(nodeName)) {
          let value = false
          nodeName.forEach((name) => {
            if (name == '*') {
              value = node.nodeName != '#text' || value
            } else {
              value = node.nodeName == name || value
            }
          })

          return value
        }
        if (nodeName == '*') {
          return node.nodeName != '#text'
        } else {
          return node.nodeName == nodeName
        }
      })

      if (!(nodes && nodes.length)) {
        nodes = Array.from(this.shadowRoot.querySelector('slot').childNodes).filter((node) => {
          if (check.string(nodeName)) {
            if (nodeName == '*') {
              return node.nodeName != '#text'
            } else {
              return node.nodeName == nodeName
            }
          }

          if (check.nonEmptyArray(nodeName)) {
            let value = false
            nodeName.forEach((name) => {
              if (name == '*') {
                value = node.nodeName != '#text' || value
              } else {
                value = node.nodeName == name || value
              }
            })

            return value
          }
        })
      }

      return nodes
    }

    firstUpdated() {
      super.firstUpdated()
      rxHelpers
        .combineLatest([this.slotNames$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(() => {
          let strategies = new Map([
            [
              'dropdown',
              {
                slotName: 'dropdown',
                handler: (e) => this.slotchangeDropdownHandler(e),
                css: () => {
                  return html`
                    <style>
                      slot[name='dropdown']::slotted(*) {
                        ${this.dropdownPosition}
                      }
                    </style>
                  `
                },
              },
            ],
            [
              'badge',
              {
                slotName: 'badge',
                css: () => {
                  return html`
                    <style>
                      slot[name='badge']::slotted(*) {
                        position: absolute;
                        top: -12px;
                        right: -12px;
                      }
                    </style>
                  `
                },
              },
            ],
            [
              'tooltip',
              {
                slotName: 'tooltip',
                handler: (e) => this.slotchangeTooltipHandler(e),
                css: () => {
                  return html`
                    <style>
                      slot[name='tooltip']::slotted(*) {
                        ${this.tooltipPosition}
                      }
                    </style>
                  `
                },
              },
            ],
          ])

          this.renderSlotsCSS = () => {
            return R.split(' ', this.slotNames)
              .filter((slotName) => {
                return strategies.get(slotName)
              })
              .map((slotName) => {
                return strategies.get(slotName) && strategies.get(slotName).css() ? strategies.get(slotName).css() : html``
              })
          }

          this.renderSlotsHTML = () => {
            return R.split(' ', this.slotNames)
              .filter((slotName) => {
                return strategies.get(slotName)
              })
              .map((slotName) => {
                return html` <slot name="${strategies.get(slotName).slotName}" @slotchange=${strategies.get(slotName).handler}></slot>`
              })
          }

          this.requestUpdate()
        })

      this.requestUpdate()
    }

    slotchangeDropdownHandler(e) {
      const dropdownPositions = () => {
        return new Map([
          ['bottomCenter', html` position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 100%); opacity: var(--showdrop, 0)`],
          ['bottomLeft', html`position: absolute; left: 0px; transform: translate(0px, 0px); opacity: var(--showdrop, 0)`],
          ['bottomRight', html`position: absolute; right: 0px; transform: translate(0px, 0px); opacity: var(--showdrop, 0)`],
          ['leftCenter', html`position: absolute; top: 50%; left: 0; transform: translate(-100%, -50%); opacity: var(--showdrop, 0)`],
          ['rightCenter', html` position: absolute; top: 50%; right: 0; transform: translate(100%, -50%); opacity: var(--showdrop, 0)`],
          ['topCenter', html` position: absolute; top: 0; left: 50%; transform: translate(-50%, -100%); opacity: var(--showdrop, 0)`],
          ['topLeft', html`position: absolute; top: 0px; left: 0px; transform: translate(0px, -100%); opacity: var(--showdrop, 0)`],
          ['topRight', html`position: absolute; top: 0px; right: 0px; transform: translate(0px, -100%); opacity: var(--showdrop, 0)`],
        ])
      }
      let dropdownPosition = Array.from(e.target.assignedNodes())[0].position
      this.dropdownPosition = dropdownPositions().get(dropdownPosition)

      let container = this.shadowRoot.querySelector('.container')
      container.addEventListener('mouseenter', () => {
        this.style.setProperty('--showdrop', 1)
        this.dropdownPosition = dropdownPositions().get(dropdownPosition)
        this.requestUpdate()
      })

      container.addEventListener('mouseleave', () => {
        this.style.setProperty('--showdrop', 0)
        this.dropdownPosition = dropdownPositions().get(dropdownPosition)
        this.requestUpdate()
      })

      this.requestUpdate()
    }

    slotchangeTooltipHandler(e) {
      const tooltipPositions = () => {
        return new Map([
          ['bottomCenter', html` position: absolute; bottom: 0px; left: 50%; transform: translate(-50%, calc(100% + 8px)); opacity: var(--showdrop, 0)`],
          ['bottomLeft', html`position: absolute; left: 0px; transform: translate(0px, 0px); opacity: var(--showdrop, 0)`],
          ['bottomRight', html`position: absolute; right: 0px; transform: translate(0px, 0px); opacity: var(--showdrop, 0)`],
          ['leftCenter', html`position: absolute; top: 50%; left: 0; transform: translate(calc(-100% - 8px), -50%); opacity: var(--showdrop, 0)`],
          ['rightCenter', html` position: absolute; top: 50%; right: 0; transform: translate(calc(100% + 8px), -50%); opacity: var(--showdrop, 0)`],
          ['topCenter', html` position: absolute; top: 0; left: 50%; transform: translate(-50%, calc(-100% - 8px)); opacity: var(--showdrop, 0)`],
          ['topLeft', html`position: absolute; top: 0px; left: 0px; transform: translate(0px, -100%); opacity: var(--showdrop, 0)`],
          ['topRight', html`position: absolute; top: 0px; right: 0px; transform: translate(0px, -100%); opacity: var(--showdrop, 0)`],
        ])
      }
      let tooltipPosition = Array.from(e.target.assignedNodes())[0].position
      this.tooltipPosition = tooltipPositions().get(tooltipPosition)

      let container = this.shadowRoot.querySelector('.container')
      container.addEventListener('mouseenter', () => {
        this.style.setProperty('--showdrop', 1)
        this.tooltipPosition = tooltipPositions().get(tooltipPosition)
        this.requestUpdate()
      })

      container.addEventListener('mouseleave', () => {
        this.style.setProperty('--showdrop', 0)
        this.tooltipPosition = tooltipPositions().get(tooltipPosition)
        this.requestUpdate()
      })

      this.requestUpdate()
    }
  }
}

export { slotMixin as default }
