export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement } = imports
  let props = () => []

  class BfFooter extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['*']
    }

    get themes() {
      return componentThemes
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)
    }

    render() {
      this.requestUpdate()
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* .container {
            display: grid;
            grid-template-columns: minmax(102px, 1fr) 1596px minmax(102px, 1fr);
            grid-template-rows: auto;
            grid-template-areas: '. main .';
            align-items: center;
            padding-top: 84px;
            padding-bottom: 84px;
          }

          .main {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            grid-area: main;
          }

          @media screen and (max-width: 1800px) {
            .container {
              grid-template-columns: 102px minmax(102px, 1596px) 102px;
            }
          }

          @media screen and (max-width: 1100px) {
            .container {
              grid-template-columns: minmax(40px, 102px) 896px minmax(40px, 102px);
            }
          }

          @media screen and (max-width: 976px) {
            .container {
              grid-template-columns: 40px minmax(40px, 896px) 40px;
            }
          }

          @media screen and (max-width: 940px) {
            ::slotted(.logo) {
              display: none;
            }
          } */

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: grid;
              grid-template-columns: 40px 1fr 40px;
              grid-template-rows: 100px;
              grid-template-areas: '. main .';
              align-items: center;
              padding-top: 84px;
              padding-bottom: 84px;
            }

            .main {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-area: main;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: grid;
              grid-template-columns: 60px 1fr 60px;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              padding-top: 84px;
              padding-bottom: 84px;
            }

            .main {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-area: main;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: grid;
              grid-template-columns: 80px 1fr 80px;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              padding-top: 84px;
              padding-bottom: 84px;
            }

            .main {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-area: main;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: grid;
              grid-template-columns: 80px 1fr 80px;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              padding-top: 84px;
              padding-bottom: 84px;
            }

            .main {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-area: main;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: grid;
              grid-template-columns: 1fr 80px 1207px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              padding-top: 84px;
              padding-bottom: 84px;
            }

            .main {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              grid-area: main;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <div class=${classMap({ main: true })}><slot @slotchange=${(e) => this.handleSlotchange(e)}></slot></div>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-footer`, BfFooter)
}

// let dataFromPath = (data, path) => path.reduce((acc, step) => acc[step], data)

// function getRendererMaker(renderers, schema) {
//   return function (uischema) {
//     return maxBy([...renderers.values()], (r) => r.tester(uischema, schema)).renderer
//   }
// }

// let getterMaker = (data, dataFromPath) => (path) => {
//   return dataFromPath(data, path)
// }

// let getter = getterMaker(data, dataFromPath)
// let get_renderer = getRendererMaker(renderers, schema)
// let render = get_renderer(uischema)
// this.formTemplate = render(get_renderer, uischema, getter, console.warn)

// ${this.formTemplate}

// <div class=${classMap({ logo: true })}><x-text theme="backflip-logo">BACKFLIP MEDIA</x-text></div>
// <x-gap theme="h-8"></x-gap>
// ${this.formTemplate}
// <x-textgroup theme="backflip-footer-contact">
//   <x-text theme="backflip-footer-bold">CONTACT</x-text>
//   <x-textgroup theme="backflip-footer-contact-info">
//     <x-text theme="backflip-footer">Nybohovsbacken 23 117 63 / Stockholm</x-text>
//     <x-text theme="backflip-footer">dylan@backflipmedia.se</x-text>
//   </x-textgroup>
// </x-textgroup>

// const schema = {
//   type: 'object',
//   properties: {
//     heading: {
//       type: 'string',
//     },
//     address: {
//       type: 'string',
//     },
//     mail: {
//       type: 'string',
//     },
//   },
// }

// const uischema = {
//   type: 'xTextgroup',
//   theme: 'backflip-footer-contact-info',
//   elements: [
//     {
//       type: 'xText',
//       theme: 'backflip-footer-bold',
//       scope: '#/properties/heading',
//     },
//     {
//       type: 'xTextgroup',
//       theme: 'backflip-footer-contact-info',
//       elements: [
//         {
//           type: 'xText',
//           theme: 'backflip-footer',
//           scope: '#properties/address',
//         },
//         {
//           type: 'xText',
//           theme: 'backflip-footer',
//           scope: '#properties/mail',
//         },
//       ],
//     },
//   ],
// }

// const data = {
//   heading: 'CONTACT',
//   address: 'Nybohovsbacken 23 117 63 / Stockholm',
//   mail: 'dylan@backflipmedia.se',
// }
