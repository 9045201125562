const initState = {
  models: [
    {
      label: 'LAST GREAT EVENT AT MEET MARKET',
      paragraphs: [
        'LAST GREAT EVENT will be pitched as part of the Meet Market pitching forum at SheffDoc International Film Festival. Looking forward to meeting new producers and hopefully new partners for the film as we move forward with this exciting project. ',
      ],
      image: { source: './images/backflip/news/last_great_event_photo_1.jpg', pattern: 'proportional-height' },
      id: 1,
    },
    {
      label: 'PREMIERE FOR MEN WHO SING',
      paragraphs: [
        'MEN WHO SING received its long awaited premieré at the SheffDoc International Film Festival. Wonderful reviews and messages from the public. The film is now planning its cinema release later in the Autumn.',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_1.jpg', pattern: 'proportional-height' },
      id: 2,
    },
    {
      label: 'RELEASE OF TRAILER FOR MEN WHO SING',
      paragraphs: [
        'MEN WHO SING releases its Trailer before its premiere at the upcoming SheffDoc International Film Festival. For a preview see here :',
        'https://www.youtube.com/watch?v=iVILMn1zFmk&t=94s',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_2.jpg', pattern: 'proportional-height' },
      id: 3,
    },
    {
      label: 'MEN WHO SING RECEIEVES A GREAT REVIEW AT SHEFFDOC',
      paragraphs: [
        'We are delighted and greatful for the response to MEN WHO SING at this years SheffDoc international Film Festival, citing Men Who Sing as',
        '”a warm-hearted …compassionate and personal film. Just the type that feels ripe for a drama remake – you can start to mentally cast the cream of veteran British actors right away!"',
        'https://businessdoceurope.com/sheffield-docfest-review-men-who-sing-by-dylan-williams/',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_3.jpg', pattern: 'proportional-height' },
      id: 4,
    },
    {
      label: 'MEN WHO SING NOMINATED FOR A CELTIC FILM AWARD',
      paragraphs: [
        'Yesterday it was announced that MEN WHO SING has been nominated for a Celtic Film Award. The prize is set up in order to celebrate the best of broadcasting, film talent and excellence from Scotland, Ireland, Galicia, Wales, Cornwall, Brittany and the Isle of Man.',
        'The prize for the winning film will be awarded at the Festival which will take place from 7th-9th of September.',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_4.jpg', pattern: 'proportional-height' },
      id: 5,
    },
    {
      label: 'Men Who Sing Cinema Release in UK.',
      paragraphs: [
        'Men Who Sing will begin its Release in Theatres beginning with its Premiere in London, Cardiff and Mold in North Wales from the 5th November. Here are the dates for screenings leading up to Christmas',
        'Picturehouse, Bath - W/c 5 November',
        'Picturehouse, Cambridge - W/c 5 November',
        'Picturehouse, Henley - W/c 5 November',
        'Picturehouse, Liverpool - W/c 5 November',
        'Picturehouse, Norwich - W/c 5 November',
        'Picturehouse, Oxford - W/c 5 November',
        'Picturehouse, Southampton - W/c 5 November',
        'Picturehouse, York - W/c 5 November',
        'Doc House, London - W/c 5 November',
        'Chapter Arts, Cardiff - W/c 5 November',
        'Theatre Clwyd, Mold - November 5, 6 & 10th',
        'Bonington Theatre, Nottingham - 10 December',
        'Picturehouse, Crouch - W/c 12 November',
        'Pontio, Bangor - 17 November',
        'Theatr Gwaun, Fishguard - 28 & 29 November',
        'Pavilions, Teignmouth - 16 December',
        'Kinokulture, Oswestry - 15&16 December',
        'Hull Independent Cinema - 21 December',
        'For more Information contact :',
        'https://www.dartmouthfilms.com/menwhosing',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_5.jpg', pattern: 'proportional-height' },
      id: 6,
    },
    {
      label: 'Men Who Sing takes home The Critics Award at BIFFL.',
      paragraphs: [
        'We are delighted to announce that MWS won the Film Critics Award at the British and Irish Film Festival in Luxembourg. More Festivals to follow!',
        'Jury’s Motivation: "We would like to honour the most optimistic and funniest film of the festival…. a film that warmed our stone cold film critic hearts." - Jean-Pierre Thilges, Association Luxembourgeoise de la Presse Cinematographique',
        'See: https://www.ifiinternational.ie/film/men-who-sing-biffl/',
      ],
      image: { source: './images/backflip/news/men_who_sing_photo_6.jpg', pattern: 'proportional-height' },
      id: 7,
    },
    {
      label: 'MWS RECEIVES FOUR AND FIVE STAR REVIEWS IN NATIONAL PRESS',
      paragraphs: [
        'Following the successful release of the Documentary in Cinemas throughout the UK, the response amongst critics were unanimous. Here are a small selection:-',
        'My favourite release of the week is a documentary called Men Who Sing (★★★★✩) and it´s a real charmer, made with manifest devotion by Dylan Williams',
        '… tremendously life-affirming in its way, full of humour and warmth, not to mention a glorious finale as the choir competes again for the first time in 15 years. I would defy anyone to watch with dry eyes.',
        'Brian Viner, THE DAILY MAIL',
        '“… adorable Welsh geezers tug at the heartstrings.”',
        'Dylan Williams’ wry eye for comedy keeps this documentary about a senior Welsh male voice choir the right side of sentimental….',
        'There aren’t many things you can count on in this world. But just as we know the sun always rises in the east, tides rise and fall on schedule, and plutonium isotopes decay at a certain rate, you know that there will be a feature film, maybe starring Jonathan Pryce or even Tom Jones, made out of this documentary about a senior Welsh male voice choir.',
        'GUARDIAN',
        'With an absolutely captivating sense of humor, this film offers a wonderful vindication of life, a demonstration that being over eighty years old does not mean giving up dreams, a spontaneous manifestation of camaraderie and a feeling of fraternity. ',
        'EL PAÌS',
      ],
      image: { source: './images/backflip/news/news8.jpeg', pattern: 'proportional-height' },
      id: 8,
    },
    {
      label: 'BEHIND THE SWEDISH MODEL TO PREMIERE AT GIFF INTERNATIONAL ',
      paragraphs: [
        'We are proud to announce that the premiére of BEHIND THE SWEDISH MODEL our co-production with Viktor Nordensköld’s Freetown Films will take place at the Gothenburg Film Festival on January 31st. ',
        'Whilst all other countries in Europe went into lockdown, Sweden steered its own course and remained a more open society. The resulting “Swedish model” became the focus for debate, ridicule and envy from leaders and people from all over the World. Documentary filmmaker Viktor Nordenskiöld’s camera followed the central figures at the heart of the crisis. The result is a highly unique insight into the processes that laid the foundations for their controversial strategy, and how the medial and political pressure affected the decision-makers.',
        'For More Information: https://program.goteborgfilmfestival.se/program/behind-the-swedish-model',
      ],
      image: { source: './images/backflip/news/news9.jpeg', pattern: 'proportional-height' },
      id: 9,
    },
    {
      label: 'BACKFLIP TO CO-PRODUCE MONDO DUPLANTIS CINEMA FEATURE ',
      paragraphs: [
        'It is with great pleasure that Backflip adds feature documentary Born to Fly to our roster. The film brings us the story of Mondo Duplantis, and the story of his rise to fame from child prodigy to the greatest Pole Vaulter of all time, becoming the Olympic Gold Medal winner and the new face of track and field at the age of 21. At the same time this intimate documentary, 5 years in the making, is a classic coming of age tale as a young man obsessed with being the best has to struggle to come to terms with the trappings and pitfalls of a whole new world. ',
        'A Link to the sizzle - https://bit.ly/2XAtooe ',
      ],
      image: { source: './images/backflip/news/news10.png', pattern: 'proportional-height' },
      id: 10,
    },
    {
      label: 'BACKFLIPMEDIA’s BORN TO FLY FÅR BIOPREMIÄR I SVERIGE DEN 30 SEPTEMBER',
      paragraphs: [
        'Nu kommer dokumentären ”Born to Fly” som skildrar hur en ung kille från Louisiana bestämmer sig för att försöka bli världens bäste stavhoppare och utmaningarna i att växa upp under strålkastarljuset. Filmen har biopremiär i Sverige den 30 september.',
        '”Born to Fly” är en lika närgången som stämningsfull och känslig berättelse om Armand ”Mondo" Duplantis och hans väg mot stjärnorna – från att ha varit ett osedvanligt talangfullt underbarn till att bli den främste stavhopparen genom tiderna.',
        '- Det är spännande att publiken nu får se en annan sida av en berättelse som de kanske är bekanta med men inte har full inblick i, säger Armand Duplantis. Men det ska också bli kul att titta själv då merparten av filmen visar mina tonår.',
        'Som 21-åring slog Mondo världsrekord, vann OS-guld och förverkligade drömmarna han haft sedan barnsben. Samtidigt är filmen av den Louisiana-baserade regissören Brennan Robideaux en uppväxtskildring, mer specifikt om hur en ung mans obevekliga driv begränsar hans möjligheter att navigera i den nya tillvaron.',
        'Fostrad i en svensk-amerikansk friidrottsfamilj i en Louisiana-småstad är Mondo något av en outsider redan i unga år, fast besluten som han är om att en dag ta sig in på stavhoppningens proffstour. Pappa Greg ifrågasätter planerna och insisterar i stället på att sonen ska gå på college och samla på sig så mycket erfarenhet som möjligt för att lättare kunna hantera alla hinder som väntar längre fram i livet. Greg vet vad han pratar om, hans egen en gång så lovande stavhopparkarriär slutade i besvikelse.',
        'Motvilligt går Mondo först sin far till mötes, men i takt med att han slår rekord efter rekord växer sig lockelsen av framgång för stark för att motstå.',
      ],
      image: { source: './images/backflip/news/mondo_jumping.jpg', pattern: 'proportional-height' },
      id: 11,
    },
  ],

  presentation: [
    {
      model_id: 11,
      id: 1,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 10,
      id: 2,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 9,
      id: 3,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 8,
      id: 4,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 6,
      id: 5,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 7,
      id: 6,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 5,
      id: 7,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 4,
      id: 8,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 1,
      id: 9,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 2,
      id: 10,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 3,
      id: 11,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
  ],
}

export { initState }
