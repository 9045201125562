let renderAlertItem = ({ component, fetch, html, render, theme, themes, direction, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    console.log('IN RENDER')
    let presentations = []
    console.log('presentationCollection', presentationCollection)
    for await (let presentationRef of presentationCollection) {
      console.log('presentationRef', presentationRef)
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.themes = themes.get(theme).module()
      model.theme = presentations[i].theme
      model.direction = direction
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderSelectorItem = ({ component, fetch, html, render, theme, themes, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    let presentations = []
    for await (let presentationRef of presentationCollection) {
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.themes = themes.get(theme).module()
      model.theme = presentations[i].theme
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderNavbarItem = ({ component, fetch, html, render, voca, template }) => {
  let { presentationCollection, modelCollection, presentationStream } = fetch
  let labelFnMap = new Map([
    ['capitalize', (label) => voca.capitalize(label)],
    ['uppercase', (label) => voca.upperCase(label)],
  ])

  presentationStream.subscribe(async () => {
    let presentations = []
    for await (let presentationRef of presentationCollection) {
      presentations.push(presentationRef.data())
    }

    let presentationOrderModelIds = presentations.map((item) => item.model_id)
    let templates = new Array(presentations.length)

    for await (let modelRef of modelCollection) {
      let model = modelRef.data()
      let i = presentationOrderModelIds.findIndex((item) => item == model.id)
      model.checked = presentations[i].selected
      model.theme = presentations[i].theme
      model.label = labelFnMap.get(presentations[i].labelFn)(model.label)
      templates[i] = template(model)
    }

    render(html``, component)
    component.requestUpdate()
    component.updateComplete.then(() => {
      render(templates, component)
      component.requestUpdate()
    })
  })
}

let renderTree = ({ component, fetch, html, render, template }) => {
  let templates = [template(fetch)]
  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let renderTreegroup = ({ component, fetch, html, render, template }) => {
  let templates = fetch.map((data) => {
    if (data.items && data.items.length) {
      return template.multi(data)
    } else {
      return template.single(data)
    }
  })

  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let renderTreebox = ({ component, fetch, html, render, template }) => {
  let templates = [template(fetch)]
  render(html``, component)
  component.requestUpdate()
  component.updateComplete.then(() => {
    render(templates, component)
    component.requestUpdate()
  })
}

let map = new Map([
  [
    'x-rate',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        console.log('HERE IN RATE')
        renderSelectorItem({
          component,
          direction: 'row',
          fetch,
          html,
          render,
          template: (data) => html`<x-rate .data=${data} slotNames="badge" theme="bare" dispatchStrategyName="alert-item">
            <x-badge slot="badge" theme="warning" label="134"></x-badge>
            <x-rate-item name="star" theme="bare"></x-rate-item>
            <x-rate-item name="star" theme="bare"></x-rate-item>
            <x-rate-item name="star" theme="bare"></x-rate-item>
            <x-rate-item name="star" theme="bare"></x-rate-item>
            <x-rate-item name="star" theme="bare"></x-rate-item>
          </x-rate> `,
          theme: 'x-rate',
          themes,
          voca,
        })
      },
  ],
  [
    'x-navbar-item',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        console.log('HERE IN ALERT')
        renderSelectorItem({
          component,
          fetch,
          html,
          render,
          template: (data) =>
            html`<x-navbar-item .data=${data} dispatchStrategyName="navbar-item" @container-click-event=${(e) => console.log('EVENT')}></x-navbar-item>`,
          theme: 'x-navbar-item',
          themes,
          voca,
        })
      },
  ],
  [
    'x-alert',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        console.log('HERE IN ALERT')
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-alert .data=${data} dispatchStrategyName="alert-item"></x-alert> `,
          theme: 'x-alert',
          themes,
          voca,
        })
      },
  ],
  [
    'x-toast',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        console.log('HERE IN ALERT')
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-toast .data=${data} dispatchStrategyName="alert-item"></x-toast> `,
          theme: 'x-toast',
          themes,
          voca,
        })
      },
  ],
  [
    'x-checkbox',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-checkbox .data=${data} dispatchStrategyName="selector-item"></x-checkbox> `,
          theme: 'x-checkbox',
          themes,
          voca,
        })
      },
  ],
  [
    'x-radiobutton',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-radiobutton .data=${data} dispatchStrategyName="selector-item"></x-radiobutton> `,
          theme: 'x-radiobutton',
          themes,
          voca,
        })
      },
  ],
  [
    'x-switch',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-switch .data=${data} dispatchStrategyName="selector-item"></x-switch> `,
          theme: 'x-switch',
          themes,
          voca,
        })
      },
  ],
  [
    'x-tab',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-tab .data=${data} dispatchStrategyName="selector-item"></x-tab> `,
          theme: 'x-tab',
          themes,
          voca,
        })
      },
  ],
  [
    'x-tag',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-tag .data=${data} dispatchStrategyName="tag"></x-tag> `,
          theme: 'x-tag',
          themes,
          voca,
        })
      },
  ],
  [
    'x-toggle',
    () =>
      ({ html, render, voca, themes }) =>
      () =>
      ({ component, fetch }) => {
        renderSelectorItem({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`<x-toggle .data=${data} dispatchStrategyName="selector-item"></x-toggle> `,
          theme: 'x-toggle',
          themes,
          voca,
        })
      },
  ],
  [
    'x-textarea',
    () =>
      ({ html, render, voca, themes }) =>
      () => ({
        items: ({ component, collectionAndOrderStream, collectionSortOrder, collection }) => {
          let labelFnMap = new Map([['capitalize', (label) => voca.capitalize(label)]])
          let template = (data) => html` <x-textarea .data=${data}></x-textarea> `

          collectionAndOrderStream.subscribe(async () => {
            let sortOrder = []
            let sortIndex = 0
            for await (let order of collectionSortOrder) {
              sortOrder.push(order.data())
            }

            let sortOrderIds = sortOrder.map((item) => item.model_id)
            let tagTemplates = new Array(sortOrder.length)

            for await (let tag of collection) {
              let tagModel = tag.data()
              let i = sortOrderIds.findIndex((item) => item == tagModel.id)
              tagModel.checked = sortOrder[i].selected
              tagModel.themes = themes.get('x-textarea').module()
              tagModel.theme = sortOrder[i].theme
              tagModel.direction = component.direction
              tagModel.label = labelFnMap.get(sortOrder[i].labelFn)(tagModel.label)
              tagTemplates[i] = template(tagModel)
              sortIndex = sortIndex + 1
            }

            render(tagTemplates, component)
            component.requestUpdate()
          })
        },
      }),
  ],
  [
    'x-textfield',
    () =>
      ({ html, render, voca, themes }) =>
      () => ({
        items: ({ component, collectionAndOrderStream, collectionSortOrder, collection }) => {
          let labelFnMap = new Map([['capitalize', (label) => voca.capitalize(label)]])
          let template = (data) => html` <x-textfield .data=${data}></x-textfield> `

          collectionAndOrderStream.subscribe(async () => {
            let sortOrder = []
            let sortIndex = 0
            for await (let order of collectionSortOrder) {
              sortOrder.push(order.data())
            }

            let sortOrderIds = sortOrder.map((item) => item.model_id)
            let tagTemplates = new Array(sortOrder.length)

            for await (let tag of collection) {
              let tagModel = tag.data()
              let i = sortOrderIds.findIndex((item) => item == tagModel.id)
              tagModel.checked = sortOrder[i].selected
              tagModel.themes = themes.get('x-textfield').module()
              tagModel.theme = sortOrder[i].theme
              tagModel.direction = component.direction
              tagModel.label = labelFnMap.get(sortOrder[i].labelFn)(tagModel.label)
              tagTemplates[i] = template(tagModel)
              sortIndex = sortIndex + 1
            }

            render(tagTemplates, component)
            component.requestUpdate()
          })
        },
      }),
  ],
  [
    'x-tree',
    () =>
      ({ html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        renderTree({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) =>
            html`
              <x-treeboxgroup
                dispatchStrategyName="treegroup"
                fetchStrategyName="treegroup"
                queryStrategyName="treegroup"
                renderItemsStrategyName="x-treegroup"
                .itemsdatasource=${data.model}
                themesName="x-treeboxgroup"
              ></x-treeboxgroup>
            `,
          theme: 'x-tab',
          voca,
        })
      },
  ],
  [
    'x-treegroup',
    () =>
      ({ html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        renderTreegroup({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: {
            single: (data) => html`
              <x-treebox
                dispatchStrategyName="treebox"
                fetchStrategyName="treebox"
                queryStrategyName="treebox"
                renderItemsStrategyName="x-treebox"
                theme="bare"
                .itemsdatasource=${data}
              ></x-treebox>
            `,
            multi: (data) => html` <x-treebox-multi theme="bare" .data=${data}></x-treebox-multi> `,
          },
          theme: 'x-tab',
          voca,
        })
      },
  ],
  [
    'x-treebox',
    () =>
      ({ html, render, voca }) =>
      () =>
      ({ component, fetch }) => {
        renderTreebox({
          component,
          direction: component.direction,
          fetch,
          html,
          render,
          template: (data) => html`
            <x-checkbox @selector-item-changed-event=${(e) => component.handleItemChanged({ component, e })} .data=${data}></x-checkbox>
          `,
          theme: 'x-tab',
          voca,
        })
      },
  ],
])

export { map as default }
