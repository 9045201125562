export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-start;
            --theme-container-column-gap: var(--spacing-4);
            --theme-container-row-gap: var(--spacing-1);
          }
        </style>
      `,
    ],
    [
      'backflip-about',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-start;
            --theme-container-row-gap: var(--spacing-10);
          }
        </style>
      `,
    ],
    [
      'backflip-film',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-start;
            --theme-container-row-gap: var(--spacing-10);
          }
        </style>
      `,
    ],
    [
      'backflip-film-description',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-start;
          }
        </style>
      `,
    ],
    [
      'backflip-about',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-start;
            --theme-container-row-gap: var(--spacing-6);
          }
        </style>
      `,
    ],
    [
      'backflip-footer-contact',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-end;
            --theme-container-row-gap: var(--spacing-2);
          }
        </style>
      `,
    ],
    [
      'backflip-footer-contact-info',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-align-items: flex-end;
          }
        </style>
      `,
    ],
  ])
}
