class MangoQueryMaker {
  constructor(arr) {
    this.conditions = arr
      .map((item) => {
        return this.makeConditions(item)
      })
      .filter((obj) => {
        return obj != undefined
      })
  }

  get selector() {
    return this.conditions.length
      ? {
          $and: this.conditions,
        }
      : undefined
  }

  get empty() {
    return !this.conditions
      .map((condition) => {
        return condition['$or'].length
      })
      .find((item) => item)
  }

  makeConditions({ selected, fieldName, conditionOperator, combinationOperator, value }) {
    selected = selected.filter((item) => {
      if (item.value === '') {
        return false
      } else return true
    })

    let result
    if (!selected.length) {
      result = { $or: [] }
    } else {
      let conditions = selected.map((item) => {
        let operator = conditionOperator
        let operand = item[value]
        let condition = { [operator]: operand }
        return { [fieldName]: condition }
      })

      let operator = combinationOperator
      result = { [operator]: conditions }
    }
    return result
  }
}

let getSelected = async (component) => {
  let { presentationCollection, modelCollection } = await component.fetchStrategy({
    datasource: await component.itemsdatasource,
    component: component,
  })

  let presentations = []
  for await (let presentationRef of presentationCollection) {
    presentations.push(presentationRef.data())
  }

  let presentationOrderModelIds = presentations.map((item) => item.model_id)

  let models = []
  for await (let modelRef of modelCollection) {
    let model = modelRef.data()
    let i = presentationOrderModelIds.findIndex((item) => item == model.id)
    model.checked = presentations[i].selected
    models.push(model)
  }

  return models.filter((model) => model.checked == true)
}

let map = new Map([
  [
    'checkbox',
    (app) => () => ({
      changed: ({ i, component }) => {
        let items = component.nodes
        let status = items.map((item, index) => {
          let indeterminate = item.indeterminate
          let checked = item.checked
          if (i == index) {
            indeterminate = false
            if (item.checked) {
              checked = false
            } else {
              checked = true
            }
          }
          return { checked: checked, id: item.id, indeterminate: indeterminate, index: index, value: item.value }
        })

        let selected = status.filter((item) => {
          return item.checked == true
        })

        return { status, selected }
      },
    }),
  ],
  [
    'radio',
    (app) => () => ({
      changed: ({ i, component }) => {
        let items = component.nodes
        let status = items.map((item, index) => {
          let checked = item.checked
          if (i == index) {
            checked = true
          } else {
            checked = false
          }
          return { checked: checked, id: item.id, index: index, value: item.value }
        })

        let selected = status.filter((item) => {
          return item.checked == true
        })

        return { status, selected }
      },
    }),
  ],
  [
    'selector',
    (app) => () => ({
      changed: ({ e, component }) => {
        return {
          nodeName: e.path[0].nodeName,
          form: component,
          value: e.detail,
        }
      },
    }),
  ],
  [
    'formbox',
    (app) => () => ({
      changed: ({ items }) => {
        let mangoQuery = new MangoQueryMaker(items.map((item) => item.query))

        return {
          query: mangoQuery.selector,
          empty: mangoQuery.empty,
          type: 'mango',
        }
      },
    }),
  ],
  [
    'tree',
    (app) => () => ({
      changed: async ({ e, component }) => {
        function getItems(item) {
          if (item.items && item.items.length) {
            return item.items.map(getItems)
          } else {
            if (item.item) {
              return item.item
            } else {
              return item
            }
          }
        }

        let selectedItems = queryStrategiesRepository.app.libs
          .get('R')
          .module()
          .flatten(e.detail.status.map(getItems))
          .filter((item) => {
            return item.checked == true
          })
          .map((item) => {
            item.value = item[component.field]
            return item
          })

        return selectedItems
      },
    }),
  ],
  [
    'treegroup',
    (app) => () => ({
      changed: async ({ e, component }) => {
        component.nodes = component.nodes && component.nodes.length != 0 ? component.nodes : Array.from(component.shadowRoot.querySelector('slot').children)

        let status = component.nodes.map((node, index) => {
          return { checked: node.checked, indeterminate: node.indeterminate, index: index, item: node.item, items: node.items }
        })

        return status
      },
    }),
  ],
  [
    'treebox',
    (app) => () => ({
      changed: async ({ e, component }) => {
        if (component.indeterminate) {
          component.checked = true
          component.indeterminate = false
        } else {
          component.checked = !component.checked
          component.indeterminate = false
        }

        component.item.checked = component.checked
        component.item.indeterminate = component.indeterminate
      },
    }),
  ],
])

export { map as default }
