let action = {}

let reducer = {}

let slices = {
  action,
  reducer,
}

export { slices as default }
