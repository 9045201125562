//This mixin makes rx Subjects that mirrors all properties

//No need for more changes

const rxMixin = ({ imports }) => () => (props, superClass) => {
  let { BehaviorSubject } = imports
  return class RxMixin extends superClass {
    firstUpdated() {
      super.firstUpdated()
      props().forEach((prop) => {
        if (prop.rx == undefined || prop.rx != false) {
          this[`${prop.propKey}$`] = new BehaviorSubject()
        }
      })
    }

    updated(changedProps) {
      super.updated(changedProps)
      changedProps.forEach((value, key) => {
        if (this[`${key}$`] !== undefined && this[`${key}$`] !== null) {
          this[`${key}$`].next(this[key])
        }
      })
    }

    static get properties() {
      return props().reduce((acc, prop) => {
        return { ...acc, [prop.propKey]: prop.propValue }
      }, {})
    }
  }
}

export { rxMixin as default }
