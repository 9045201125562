import registerComponents from './modules/components'
import css from './modules/css'
import libs from './modules/libs'
import dataProviders from './modules/dataProviders'
import dispatchStrategies from './modules/dispatchStrategies'
import fetchStrategies from './modules/fetchStrategies'
import icons from './modules/icons'
import mixins from './modules/mixins'
import modules from './modules/modules'
import queryStrategies from './modules/queryStrategies'
import renderStrategies from './modules/renderStrategies'
import slotStrategies from './modules/slotStrategies'
import themes from './modules/themes'
import updateItemsStrategies from './modules/updateItemsStrategies'

let register = new Map()

register.set('components', registerComponents)

let registrate = (map) => (repository) => {
  map.forEach((value, key) => {
    repository.register({
      name: key,
      initialize: value,
    })
  })

  return repository
}

let map = new Map([
  ['css', css],
  ['dataProviders', dataProviders],
  ['dispatchStrategies', dispatchStrategies],
  ['fetchStrategies', fetchStrategies],
  ['icons', icons],
  ['libs', libs],
  ['mixins', mixins],
  ['modules', modules],
  ['queryStrategies', queryStrategies],
  ['renderStrategies', renderStrategies],
  ['slotStrategies', slotStrategies],
  ['themes', themes],
  ['updateItemsStrategies', updateItemsStrategies],
])

map.forEach((value, key) => {
  register.set(key, registrate(value))
})

export { register as default }
