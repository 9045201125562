const dragMixin = ({ imports }) => () => (superClass) => {
  let { html, R, redux, rxHelpers } = imports
  const store = redux.store
  const action = redux.action

  return class DragMixin extends superClass {
    dragLeave(e) {
      e.stopPropagation()

      if (e.clientX < this.getBoundingClientRect().x) {
        console.log('LEAVE OUTSIDE')
        this.movingElementWrap.style.opacity = '1'
      }
    }

    getElementByClassNameFromEvent({ event, className }) {
      return event.path.find((node) => {
        return node.classList ? Array.from(node.classList).find((name) => name == className) : false
      })
    }

    removeAllInnerElementWithClassName({ element, className }) {
      Array.from(element.querySelectorAll('.' + className)).forEach((element) => {
        element.parentNode.removeChild(element)
      })
    }

    createPlus() {
      let div = document.createElement('div')
      div.classList.add('plus')
      div.style.position = 'absolute'
      div.style.top = '-10px'
      div.style.left = '-10px'
      div.innerHTML = 'HEJ'
      return div
    }

    dragEnter(e) {
      let targetElementWrap = this.getElementByClassNameFromEvent({ event: e, className: 'drag-wrap' })
      this.removeAllInnerElementWithClassName({ element: targetElementWrap.parentNode, className: 'plus' })
      targetElementWrap.appendChild(this.createPlus())
    }

    dragOver(e) {
      e.preventDefault()
    }

    dragStart(e) {
      this.movingElement = e.target
      this.movingElementWrap = this.movingElement.parentNode
      // MAKE EVENT
      store.dispatch(action.dragDataTransfer({ id: e.target.id, path: this.collectionName }))
      // store.dispatch({ type: 'crud/dragDataTransfer', payload: { id: e.target.id, path: this.collectionName } })
    }

    async drop(e) {
      e.preventDefault()
      e.stopPropagation()
      let targetElementWrap = e.path.find((node) => {
        return node.classList ? Array.from(node.classList).find((name) => name == 'drag-wrap') : false
      })

      if (targetElementWrap) {
        let targetElement = Array.from(targetElementWrap.childNodes).find((node) => node.nodeName == 'X-TAG')
        // MAKE EVENT

        console.log('store.getState()', store.getState().reduxStoreReducer.dragDataTransfer)
        let storePath = store.getState().reduxStoreReducer.dragDataTransfer.path
        let collectionToDeleteFrom = this.store.collection(storePath)
        let targetId = targetElement.id
        let itemId = store.getState().reduxStoreReducer.dragDataTransfer.id
        let doc = await this.collection.doc(itemId).get()
        let item = doc.data()

        await collectionToDeleteFrom
          .doc(itemId)
          .delete()
          .then(async (obj) => {
            console.log('delete: ', obj)
            await this.collectionSortOrder.throw({ key: 'ref_id', ref_id: obj.payload.id }).then((ref) => {
              console.log('throw: ', ref)
            })
          })

        await this.collection.add(item).then(async (ref) => {
          console.log('add: ', item)
          await this.collectionSortOrder.insertBefore({ key: 'ref_id', ref_id: targetId, item: { ref_id: ref.code } }).then((ref) => {
            console.log('insertBefore: ', ref)
          })
        })

        this.requestUpdate()
      }
    }

    dragWrapTemplate(item) {
      return html`<div
        class="drag-wrap"
        @dragenter=${(e) => this.dragEnter(e)}
        @dragover=${(e) => this.dragOver(e)}
        @dragleave=${(e) => this.dragLeave(e)}
        @dragstart=${(e) => this.dragStart(e)}
        @drop=${(e) => this.drop(e)}
      >
        ${item}
      </div>`
    }
  }
}

export { dragMixin as default }
