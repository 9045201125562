//TODO: Remove need for this mixin

const crudMixin = ({ imports }) => () => (superClass) => {
  let { axios, PouchDB, R } = imports

  return class CrudMixin extends superClass {
    constructor() {
      super()
    }

    deleteObject(type, id) {
      return axios({
        method: 'delete',
        url: `http://localhost:4000/${type}/${id}`,
      })
    }

    deleteObjects(type, ids) {
      return axios.all(
        ids.map((id) => {
          return this.deleteObject(type, id)
        }),
      )
    }

    putObject(type, id, data) {
      return axios({
        method: 'put',
        url: `http://localhost:4000/${type}/${id}`,
        data: data,
      })
    }

    putObjects(type, ids, dataArray) {
      return axios.all(
        ids.map((id, index) => {
          return this.getObject(type, id)
            .then((object) => {
              return R.merge(object.data, dataArray[index])
            })
            .then((mergedObject) => {
              return this.putObject(type, id, mergedObject)
            })
        }),
      )
    }

    downloadObjects(type, ids, that) {
      return axios.all(
        ids.map((id) => {
          return this.getObject(type, id).then((object) => {
            return axios({
              url: object.data.url,
              method: 'GET',
              responseType: 'blob',
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'file.png')
              that.shadowRoot.appendChild(link)
              link.click()
            })
          })
        }),
      )
    }

    getObject(type, id) {
      return axios({
        method: 'get',
        url: `http://localhost:4000/${type}/${id}`,
      })
    }

    getObjects(obj) {
      return axios({
        method: 'get',
        url: `http://localhost:3300/api/${obj.type}`,
        params: obj,
      })
    }

    async getRowsFromIds(intersectIds) {
      const dbURL = 'http://plex:1111111111@127.0.0.1:5984/year'
      let db = new PouchDB(dbURL)
      let docs = await db
        .bulkGet({
          docs: intersectIds,
        })
        .catch(function (err) {
          console.log(err)
          throw err
        })
      return docs.results.map((result) => {
        // console.log(result)
        return result.docs[0].ok
      })
    }

    async getRowFromId(id) {
      const dbURL = 'http://plex:1111111111@127.0.0.1:5984/year'
      let db = new PouchDB(dbURL)
      let doc = await db.get(id).catch(function (err) {
        console.log(err)
        throw err
      })
      return doc
    }
  }
}

export { crudMixin as default }
