let map = new Map([
  ['fetchStoreMaker', (app) => async (path) => {
      return new (app.modules.get('FetchStore').module())(path)
    }],
  ['reduxStoreMaker', (app) => async () => {
      return new (app.modules.get('ReduxStore').module())()
    }],
  ['mutableFetchStoreMaker', (app) => async (path) => {
      return new (app.modules.get('MutableFetchStore').module())(path)
    }],
  ['mutableReduxStoreMaker', (app) => async () => {
      return new (app.modules.get('MutableReduxStore').module())()
    }],
      ['transactionalReduxStoreMaker', (app) => async () => {
      return new (app.modules.get('TransactionalReduxStore').module())()
    }],
])

export { map as default }
