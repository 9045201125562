// import Batch from '../modules/Stores/Batches/Batch'
import BrowserChecker from '../modules/BrowserChecker'
// import Collection from '../modules/Stores/Collections/Collection'
// import Document from '../modules/Stores/Documents/Document'
// import DocumentReference from '../modules/Stores/Documents/DocumentReference'
// import FetchCollection from '../modules/Stores/Collections/FetchCollection'
// import FetchStore from '../modules/Stores/FetchStore'
// import MutableFetchStore from '../modules/Stores/MutableFetchStore'
// import MutableFetchCollection from '../modules/Stores/Collections/MutableFetchCollection'
// import MutableReduxCollection from '../modules/Stores/Collections/MutableReduxCollection'
// import MutableReduxSlice from '../modules/Stores/Slices/MutableReduxSlice'
// import MutableReduxStore from '../modules/Stores/MutableReduxStore'
import Router from '@whcg/x-router'
import Redux from '../modules/Redux'
// import ReduxBatch from '../modules/Stores/Batches/ReduxBatch'
// import ReduxCollection from '../modules/Stores/Collections/ReduxCollection'
// import ReduxSlice from '../modules/Stores/Slices/ReduxSlice'
import RxHelpers from '@whcg/x-rx-helpers'
import SetOperations from '@whcg/x-set-operations'
// import Slice from '../modules/Stores/Slices/Slice'
import Stores from '@whcg/x-stores'
// import ReduxStore from '../modules/Stores/ReduxStore'
// import TransactionalReduxStore from '../modules/Stores/TransactionalReduxStore'
// import WhereReference from '../modules/Stores/Where/WhereReference'

let Batch = Stores.Batches.Batch
let Collection = Stores.Collections.Collection
let Document = Stores.Documents.Document
let DocumentReference = Stores.Documents.DocumentReference
let FetchCollection = Stores.Collections.FetchCollection
let FetchStore = Stores.FetchStore
let MutableFetchStore = Stores.MutableFetchStore
let MutableFetchCollection = Stores.Collections.MutableFetchCollection
let MutableReduxCollection = Stores.Collections.MutableReduxCollection
let MutableReduxSlice = Stores.Slices.MutableReduxSlice
let MutableReduxStore = Stores.MutableReduxStore
let ReduxBatch = Stores.Batches.ReduxBatch
let ReduxCollection = Stores.Collections.ReduxCollection
let ReduxSlice = Stores.Slices.ReduxSlice
let ReduxStore = Stores.ReduxStore
let Slice = Stores.Slices.Slice
let TransactionalReduxStore = Stores.TransactionalReduxStore
let WhereReference = Stores.Where.WhereReference

let map = new Map([
  ['Batch', (app) => Batch(app)],
  ['BrowserChecker', (app) => BrowserChecker],
  ['Collection', (app) => Collection(app)],
  ['Document', (app) => Document(app)],
  ['DocumentReference', (app) => DocumentReference(app)],
  ['FetchCollection', (app) => FetchCollection(app)],
  ['FetchStore', (app) => FetchStore(app)],
  ['MutableFetchCollection', (app) => MutableFetchCollection(app)],
  ['MutableFetchStore', (app) => MutableFetchStore(app)],
  ['MutableReduxCollection', (app) => MutableReduxCollection(app)],
  ['MutableReduxSlice', (app) => MutableReduxSlice(app)],
  ['MutableReduxStore', (app) => MutableReduxStore(app)],
  ['Router', (app) => Router(app)],
  ['Redux', (app) => Redux(app)],
  ['ReduxCollection', (app) => ReduxCollection(app)],
  ['RxHelpers', (app) => RxHelpers(app)],
  ['SetOperations', (app) => SetOperations(app)],
  ['Slice', (app) => Slice(app)],
  ['ReduxBatch', (app) => ReduxBatch(app)],
  ['ReduxSlice', (app) => ReduxSlice(app)],
  ['ReduxStore', (app) => ReduxStore(app)],
  ['TransactionalReduxStore', (app) => TransactionalReduxStore(app)],
  ['WhereReference', (app) => WhereReference(app)],
])

export { map as default }
