export default (imports) => {
  let { html, componentThemes, baseMixin, classMap, frontendRoute, LitElement } = imports
  let props = () => [
    { propKey: 'checked', propValue: { type: Boolean } },
    { propKey: 'imageUrl', propValue: { type: String } },
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'theme', propValue: { type: String } },
    { propKey: 'type', propValue: { type: String } },
  ]

  class BfInproductionBItem extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.checked = false
      this.disabled = false
      this.label = 'No label'
      this.type = 'click'
      this.displayNone = false
    }

    get themes() {
      return componentThemes
    }

    handleClose(e) {
      e.stopPropagation()

      this.displayNone = true
      let event = new CustomEvent('remove-element-event', {})
      this.dispatchEvent(event)
      this.requestUpdate()
    }

    render() {
      let flex = html`
        <style>
          .container {
            /* display: flex;
            flex-direction: ${this.direction == 'row'
              ? 'row'
              : 'column'};
            column-gap: var(--theme-container-column-gap, 100px);
            row-gap: var(--spacing-5); */
          }
        </style>
      `
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()} ${this.flex ? flex : ''}
        <style>
          .container {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            /* align-items: flex-start; */
            padding-bottom: 50px;
          }

          .about-main {
            display: flex;
            /* max-width: 627px; */
            flex-direction: column;
            row-gap: 16px;
            padding-top: 20px;
            padding-left: 246px;
          }
          .image {
            position: relative;
            max-width: 100%;
            height: 630px;
            left: 0px;
            top: 0px;

            /* object-fit: cover; */
          }

          .content {
            display: flex;
            column-gap: 64px;
          }

          .text {
            max-width: 627px;
          }

          .description {
            max-width: 500px;
          }
        </style>
        <div class="container">
          <img src=${'./images/mwsbg.jpg'} class="image" alt="..." />
          <div class=${classMap({ 'about-main': true })}>
            <x-text theme="backflip-header">MEN WHO SING</x-text>
            <div class=${classMap({ content: true })}>
              <bf-description class=${classMap({ description: true })} theme="backflip-film-description"></bf-description>
              <x-textgroup class=${classMap({ text: true })} theme="backflip-about">
                <x-text theme="backflip-body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                  anim id est laborum.
                </x-text>
                <x-text theme="backflip-body">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                </x-text>
              </x-textgroup>
            </div>
          </div>
        </div>
      `
    }
  }

  customElements.define(`bf-inproduction-b-item`, BfInproductionBItem)
}
