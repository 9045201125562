let props = () => [
  { propKey: 'checked', propValue: { type: Boolean } },
  { propKey: 'icon', propValue: { type: Boolean } },
  { propKey: 'iconName', propValue: { type: String } },
  { propKey: 'iconProvider', propValue: { type: String } },
  { propKey: 'id', propValue: { type: Number } },
  { propKey: 'indeterminate', propValue: { type: Boolean } },
  { propKey: 'label', propValue: { type: String } },
  { propKey: 'value', propValue: { type: String } },
]

export default ({ imports }) => () => (baseMixin, superClass) => {
  let { classMap, html } = imports
  return class SelectorItem extends baseMixin(props, superClass) {
    constructor() {
      super()
      this.checked = false
      this.id = undefined
      this.indeterminate = false
      this.label = 'No label'
      this.value = 'No value'
      this.direction = 'row'
      this.icon = false
      this.iconName = 'search'
      this.iconProvider = 'no-provider'
    }

    firstUpdated() {
      super.firstUpdated()
    }

    handleClick(e) {
      e.stopPropagation()
      this.dispatchStrategy.changed({ dispatcher: this })
      this.requestUpdate()
    }

    text(label) {
      return html`<div class=${classMap({ label: true })}>
        <x-text theme="regular-2 regular-2-center">${label}</x-text>
      </div>`
    }

    toRender({ icon, input, text }) {
      return html` <div class=${classMap({ container: true, checked: this.checked, indeterminate: this.indeterminate })}>
        <div class=${classMap({ checkbox: true })}>${input()} ${icon()}</div>
        ${text} ${this.renderSlotsHTML()}
      </div>`
    }

    render({ icon, input, text }) {
      return this.toRender({ icon, input, text })
    }
  }
}
