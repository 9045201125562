import axios from 'axios/dist/axios.js'
import { default as check } from 'check-types'
import { classMap } from 'lit-html/directives/class-map.js'
import * as litElement from 'lit-element'
import * as litHtml from 'lit-html'
import lodash from 'lodash'
import logger from 'redux-logger'
import maxBy from 'lodash/maxBy'
import PouchDB from 'pouchdb/dist/pouchdb.js'
import * as R from 'ramda/es/index.js'
import * as redux from 'redux/es/redux'
import { installRouter } from 'pwa-helpers/router.js'
// import rxHelpers from 'rx-helpers'
import * as rxjs from 'rxjs'
import * as rxjsOperators from 'rxjs/operators'
import voca from 'voca'

let map = new Map([
  ['axios', () => () => axios],
  ['check', () => () => check],
  ['classMap', () => () => classMap],
  ['firebase', () => () => firebase],
  ['installRouter', () => () => installRouter],
  ['lit-element', () => () => litElement],
  ['lodash', () => () => lodash],
  ['logger', () => () => logger],
  ['maxBy', () => () => maxBy],
  ['PouchDB', () => () => PouchDB],
  ['R', () => () => R],
  ['redux', () => () => redux],
  ['lit-html', () => () => litHtml],
  // ['rxHelpers', () => () => rxHelpers],
  ['rxjs', () => () => rxjs],
  ['rxjs/operators', () => () => rxjsOperators],
  ['voca', () => () => voca],
])

export { map as default }
