export default (imports) => {
  let { html, classMap, componentThemes, baseMixin, frontendRoute, LitElement } = imports
  let props = () => [
    { propKey: 'checked', propValue: { type: Boolean } },
    { propKey: 'imageUrl', propValue: { type: String } },
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'theme', propValue: { type: String } },
    { propKey: 'type', propValue: { type: String } },
  ]

  class BfFilmsItem extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.checked = false
      this.disabled = false
      this.label = 'No label'
      this.type = 'click'
      this.displayNone = false
      this.direction = 'column'
    }

    get themes() {
      return componentThemes
    }

    // firstUpdated() {
    //   super.firstUpdated()
    //   let imageElem = this.shadowRoot.querySelector('.imagebox')
    //   const resizeObserver = new ResizeObserver((entries) => {
    //     for (let entry of entries) {
    //       console.log('ENTRY: ', entry)
    //       if (entry.contentBoxSize) {
    //         console.log('CONTENT RECT: ', entry.contentRect)
    //       } else {
    //         // Firefox implements `contentBoxSize` as a single content rect, rather than an array
    //         // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    //       }
    //     }

    //     console.log('Size changed')
    //   })

    //   resizeObserver.observe(imageElem)
    // }

    handleClose(e) {
      e.stopPropagation()

      this.displayNone = true
      let event = new CustomEvent('remove-element-event', {})
      this.dispatchEvent(event)
      this.requestUpdate()
    }

    handleContainerClick(e) {
      e.stopPropagation()
      this.dispatchStrategy.containerClick({ dispatcher: this })
      this.requestUpdate()
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* :host {
            max-width: 522px;
          }
          .container {
            display: flex;
            flex-direction: column;
            row-gap: var(--spacing-5);
          }

          .imagebox {
            flex: 0 0 auto;
          }

          .body {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 60px;
          }

          .heading {
            flex: 0 0 auto;
          }

          .description {
            flex: 0 0 auto;
          } */

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: var(--spacing-5);
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .body {
              flex: 1;
              display: flex;
              flex-direction: column;
              /* padding-left: 60px; */
            }

            .heading {
              flex: 0 0 auto;
            }

            .description {
              flex: 0 0 auto;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: var(--spacing-5);
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .body {
              flex: 1;
              display: flex;
              flex-direction: column;
              /* padding-left: 60px; */
            }

            .heading {
              flex: 0 0 auto;
            }

            .description {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: var(--spacing-5);
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .body {
              flex: 1;
              display: flex;
              flex-direction: column;
              /* padding-left: 60px; */
            }

            .heading {
              flex: 0 0 auto;
            }

            .description {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: var(--spacing-5);
            }

            .imagebox {
              flex: 0 0 auto;
            }

            .body {
              flex: 1;
              display: flex;
              flex-direction: column;
              /* padding-left: 60px; */
            }

            .heading {
              flex: 0 0 auto;
            }

            .description {
              flex: 0 0 auto;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: var(--spacing-5);
            }

            .imagebox {
              flex: 0 0 auto;
              /* align-self: flex-end; */
              /* padding-right: 10%; */
            }

            .body {
              flex: 1;
              display: flex;
              flex-direction: column;
              /* padding-left: 60px; */
            }

            .heading {
              flex: 0 0 auto;
            }

            .description {
              flex: 0 0 auto;
            }
          }
        </style>
        <div class="container" @click=${(e) => this.handleContainerClick(e)}>
          <x-image class=${classMap({ imagebox: true })} .data=${this.posterimage}></x-image>

          <div class="body">
            <x-text class=${classMap({ heading: true })} theme="backflip-header">${this.label}</x-text>
            <bf-description
              class=${classMap({ description: true })}
              .data=${{ description: this.description }}
              theme="backflip-film-description"
            ></bf-description>
          </div>
        </div>
      `
    }
  }

  customElements.define(`bf-films-item`, BfFilmsItem)
}

// <img src=${this.image} class="image" alt="..." />
