export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, messenger, transactionalLocalStore } = imports
  let props = () => [
    { propKey: 'biglogo', propValue: { type: Boolean } },
    { propKey: 'navbarVisible', propValue: { type: Boolean } },
  ]

  class BfHeader extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = []
      this.layout = 'flex-column'
      this.biglogo = false
      this.navbarVisible = false
    }

    get themes() {
      return componentThemes
    }

    firstUpdated() {
      super.firstUpdated()

      messenger.subscribe((message) => {
        if (message && message.header == 'bf-menu-changed') {
          this.navbarVisible = false
        }
      })
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)
    }

    hamburgerClickHandler(e) {
      this.navbarVisible = !this.navbarVisible
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: grid;
              grid-template-columns: 40px 1fr 40px;
              grid-template-rows: 100px;
              grid-template-areas: '. main .';
              align-items: center;
              background-color: white;
              opacity: 100%;
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: auto 1fr auto;
              grid-template-rows: 100px;
              grid-template-areas: 'logo . hamburger';
              align-items: center;
            }

            .logo {
              color: black;
              grid-area: logo;
            }

            .hamburger {
              grid-area: hamburger;
              position: relative;
              height: 16px;
              width: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .hamburger-input {
              position: absolute;
              height: 16px;
              width: 20px;
              top: 0;
              left: 0;
              opacity: 0;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 0px;
            }

            .hamburger-line {
              height: 2px;
              background-color: black;
            }

            .navbar {
              position: fixed;
              top: 100px;
              right: 0;
              overflow: hidden;
              max-width: ${this.navbarVisible ? '100%' : '0px'};
              transition: 190ms max-width linear;
              -webkit-transition: 190ms max-width linear; 
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: grid;
              grid-template-columns: 60px 1fr 60px;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              background-color: white;
              opacity: 100%;
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: auto 1fr auto;
              grid-template-rows: 100px;
              grid-template-areas: 'logo . hamburger';
              align-items: center;
            }

            .logo {
              color: black;
              grid-area: logo;
            }

            .hamburger {
              grid-area: hamburger;
              position: relative;
              height: 16px;
              width: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .hamburger-input {
              position: absolute;
              height: 16px;
              width: 20px;
              top: 0;
              left: 0;
              opacity: 0;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 0px;
            }

            .hamburger-line {
              height: 2px;
              background-color: black;
            }

            .navbar {
              position: fixed;
              top: 100px;
              right: 0;
              overflow: hidden;
              max-width: ${this.navbarVisible ? '100%' : '0px'};
              transition: 190ms max-width linear;
              -webkit-transition: 190ms max-width linear; 
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: grid;
              grid-template-columns: 80px 1fr 80px ;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              background-color: white;
              opacity: 100%;
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: auto 1fr auto;
              grid-template-rows: 100px;
              grid-template-areas: 'logo . hamburger';
              align-items: center;
            }

            .logo {
              color: black;
              grid-area: logo;
            }

            .hamburger {
              grid-area: hamburger;
              position: relative;
              height: 16px;
              width: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .hamburger-input {
              position: absolute;
              height: 16px;
              width: 20px;
              top: 0;
              left: 0;
              opacity: 0;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 0px;
            }

            .hamburger-line {
              height: 2px;
              background-color: black;
            }

            .navbar {
              position: fixed;
              top: 100px;
              right: 0;
              overflow: hidden;
              max-width: ${this.navbarVisible ? '100%' : '0px'};
              transition: 190ms max-width linear;
              -webkit-transition: 190ms max-width linear; 
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .logo {
              color: black;
              grid-area: logo;
            }

            .navbar {
              grid-area: navbar;
            }

            .main {
              grid-area: main;
            }

            .container {
              display: grid;
              grid-template-columns: 80px 1fr 80px ;
              grid-template-rows: auto;
              grid-template-areas: '. main .';
              align-items: center;
              opacity: 100%;
            }

            .main {
              display: grid;
              grid-template-columns: auto 1fr auto;
              grid-template-rows: 100px;
              grid-template-areas: 'logo . navbar';
              align-items: center;
            }

            .hamburger {
              display: none;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .logo {
              color: black;
              grid-area: logo;
            }

            .navbar {
              grid-area: navbar;
            }

            .main {
              grid-area: main;
            }

            .container {
              display: grid;
              grid-template-columns: 1fr 80px 1207px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              opacity: 100%;
            }

            .main {
              display: grid;
              grid-template-columns: auto 1fr auto;
              grid-template-rows: 100px;
              grid-template-areas: 'logo . navbar';
              align-items: center;
            }

            .hamburger {
              display: none;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <div class=${classMap({ main: true })}>
            <div class=${classMap({ logo: true })}><x-text theme=${'backflip-logo'}>BACKFLIP MEDIA</x-text></div>
            <div class=${classMap({ hamburger: true })}>
              <input type="checkbox" class=${classMap({ 'hamburger-input': true })} @change=${(e) => this.hamburgerClickHandler(e)}></input>
              <div class=${classMap({ 'hamburger-line': true })}></div>
              <div class=${classMap({ 'hamburger-line': true })}></div>
              <div class=${classMap({ 'hamburger-line': true })}></div>
            </div>
            <x-navbar
              .itemsdatasource=${{
                store: transactionalLocalStore,
                models: 'models',
                presentations: 'presentation',
                slice: 'bfMenu',
              }}
              dispatchStrategyName="navbar"
              fetchStrategyName="slice"
              queryStrategyName="radio"
              renderItemsStrategyName="bf-menu-item"
              class=${classMap({ navbar: true })}
              theme="backflip"
            >
            </x-navbar>
          </div>
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-header`, BfHeader)
}
