const orderMixin = ({ imports }) => () => (superClass) => {
  let { html, R, rxHelpers } = imports
  return class OrderMixin extends superClass {
    addOrderClassNames(node, total, i) {
      if (i == 0) {
        node.classList.add('first')
        node.classList.add('not-last')
      } else if (i == total - 1) {
        node.classList.add('last')
        node.classList.add('not-first')
      } else {
        node.classList.add('not-first')
        node.classList.add('not-last')
      }
    }

    addOrderAttributes(node, total, i) {
      if (i == 0) {
        node.setAttribute('first', 'first')
      } else if (i == total - 1) {
        node.setAttribute('last', 'last')
      }
    }

    prepareNodes(nodes) {
      if (super.prepareNodes) {
        super.prepareNodes(nodes)
      }
      nodes.forEach((node, i) => {
        this.addOrderAttributes(node, nodes.length, i)
        this.addOrderClassNames(node, nodes.length, i)
      })
    }
  }
}

export { orderMixin as default }
