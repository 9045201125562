import { reduxBatch } from '@manaflair/redux-batch'

import slices from '../../Application/slices'

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

let Redux = (app) => () => {
  return class Redux {
    constructor() {
      let { logger } = app.imports
      this.logger = logger
      this.storeCreator(this.initState)
    }

    get action() {
      return slices.action
    }

    get initState() {
      return {}
    }

    storeCreator(preloadedState) {
      this.store = configureStore({
        reducer: slices.reducer,
        middleware: [this.logger, ...getDefaultMiddleware()],
        // middleware: [...getDefaultMiddleware()],
        enhancers: [reduxBatch],
        preloadedState,
      })
      return this.store
    }
  }
}

export { Redux as default }
