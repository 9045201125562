import xGap from '@whcg/x-gap'
import xIcon from '@whcg/x-icon'
import xImage from '@whcg/x-image'
import xNavbar from '@whcg/x-navbar'
import xNavbarItem from '@whcg/x-navbar-item'
import xText from '@whcg/x-text'
import xTextgroup from '@whcg/x-textgroup'
import xVideo from '@whcg/x-video'

let map = new Map([
  ['x-gap', xGap],
  ['x-icon', xIcon],
  ['x-image', xImage],
  ['x-navbar', xNavbar],
  ['x-navbar-item', xNavbarItem],
  ['x-text', xText],
  ['x-textgroup', xTextgroup],
  ['x-video', xVideo],
])

export { map as default }
