export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, transactionalLocalStore } = imports
  let props = () => []

  class BfFilmsPage extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['*']
    }

    get themes() {
      return componentThemes
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: grid;
              grid-template-columns: 40px 0px 1fr 0px 40px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              grid-area: main;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: grid;
              grid-template-columns: 60px 0px 1fr 0px 60px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              grid-area: main;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              grid-area: main;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
              align-items: center;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              grid-area: main;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: grid;
              grid-template-columns: 1fr 80px 0px 1207px 0px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . . main . . .';
              align-items: center;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              grid-area: main;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <bf-films-itemgroup
            class="main"
            layout="grid-4"
            .itemsdatasource=${{
              store: transactionalLocalStore,
              models: 'models',
              presentations: 'presentation',
              slice: 'bfFilms',
            }}
            fetchStrategyName="slice"
            renderItemsStrategyName="bf-films-item"
            queryStrategyName="radio"
            dispatchStrategyName="bf-films-itemgroup"
            theme="backflip"
          ></bf-films-itemgroup>
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-films-page`, BfFilmsPage)
}
