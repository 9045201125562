export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-0);
          }
        </style>
      `,
    ],
    [
      'h-0',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-0);
          }
        </style>
      `,
    ],
    [
      'h-1',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-1);
          }
        </style>
      `,
    ],
    [
      'h-2',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-2);
          }
        </style>
      `,
    ],
    [
      'h-3',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-3);
          }
        </style>
      `,
    ],
    [
      'h-4',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-4);
          }
        </style>
      `,
    ],
    [
      'h-5',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-5);
          }
        </style>
      `,
    ],
    [
      'h-6',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-6);
          }
        </style>
      `,
    ],
    [
      'h-7',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-7);
          }
        </style>
      `,
    ],
    [
      'h-8',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-8);
          }
        </style>
      `,
    ],
    [
      'h-9',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-9);
          }
        </style>
      `,
    ],
    [
      'h-10',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-10);
          }
        </style>
      `,
    ],
    [
      'h-11',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-11);
          }
        </style>
      `,
    ],
    [
      'h-12',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-12);
          }
        </style>
      `,
    ],
    [
      'h-13',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-13);
          }
        </style>
      `,
    ],
    [
      'h-14',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-14);
          }
        </style>
      `,
    ],
    [
      'h-15',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-15);
          }
        </style>
      `,
    ],
    [
      'h-16',
      html`
        <style>
          * {
            --theme-container-height: var(--spacing-16);
          }
        </style>
      `,
    ],
  ])
}
