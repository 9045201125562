// import * as R from 'ramda'

import { initState } from './state'

import { createSlice } from '@reduxjs/toolkit'

let tmp

const bfNewsSlice = createSlice({
  name: 'bfNews',
  initialState: initState,
  reducers: {
    putItem(state, action) {
      state.models.forEach((item) => {
        if (item.id == action.payload.id) {
          Object.entries(action.payload.item).forEach((entry) => {
            item[entry[0]] = entry[1]
          })
        }
      })
    },

    add(state, action) {
      tmp = {}
      tmp.item = action.payload.item
      tmp.item.id = Number(state.models[Number(state.models.length) - 1].id) + 1
      state.models.push(tmp.item)
      state.presentation.push({
        model_id: tmp.item.id,
        id: Number(state.presentation[Number(state.presentation.length) - 1].id) + 1,
        selected: false,
        theme: 'onblack',
        labelFn: 'capitalize',
      })
    },

    delete(state, action) {
      state.models.forEach((item) => {
        if (item.id == action.payload.id) {
          item.deleted = true
        }
      })
    },
    set(state, action) {
      state.models.forEach((item) => {
        if (item.id == action.payload.id) {
          for (let prop in item) {
            delete item[prop]
          }
          item.id = action.payload.id
          Object.entries(action.payload.item).forEach((entry) => {
            item[entry[0]] = entry[1]
          })
        }
      })
    },
    setMerge(state, action) {
      state[action.payload.path].forEach((item) => {
        if (item.id == action.payload.id) {
          Object.entries(action.payload.item).forEach((entry) => {
            item[entry[0]] = entry[1]
          })
        }
      })
    },
  },
})

export default bfNewsSlice
