export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-opacity: 1;
          }
        </style>
      `,
    ],
    [
      'transparent',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-opacity: 0.05;
          }
        </style>
      `,
    ],
  ])
}
