let getModelRef = async ({ modelsCollection, presentationRef }) => await modelsCollection.doc(presentationRef.data().model_id).get()
let selectionIncludesRefIdentifier = ({ selection, ref, identifier }) => {
  // console.log(
  //   selection.map((item) => {
  //     return item[identifier]
  //   }),
  // )
  if (ref.exists) {
    return selection
      .map((item) => {
        return item[identifier]
      })
      .includes(ref.data()[identifier])
  } else {
    return false
  }
}

let storeUpdate = async ({ itemsdatasource, selection }) => {
  // console.log('SELECTION: ', selection)
  let store = itemsdatasource.store

  let modelsCollection = store.slice(itemsdatasource.slice).collection(itemsdatasource.models)
  let presentationCollection = store.slice(itemsdatasource.slice).collection(itemsdatasource.presentations)
  let batch = store.batch()

  for await (let presentationRef of presentationCollection) {
    let modelRef = await getModelRef({ modelsCollection, presentationRef: presentationRef })
    if (selectionIncludesRefIdentifier({ selection, ref: modelRef, identifier: 'id' })) {
      batch.update(presentationCollection.doc(presentationRef.data().id), { selected: true })
    } else {
      batch.update(presentationCollection.doc(presentationRef.data().id), { selected: false })
    }
  }
  batch.commit()
}

let map = new Map([
  [
    'bf-films-item',
    () => () => ({
      containerClick: ({ dispatcher }) => {
        dispatcher.dispatchEvent(new CustomEvent('container-click-event'))
      },
    }),
  ],
  [
    'bf-films-itemgroup',
    (app) => () => ({
      changed: async ({ dispatcher, body }) => {
        let selection = body.selected
        let itemsdatasource = await dispatcher.itemsdatasource

        await storeUpdate({ itemsdatasource, selection })
        app.imports.messenger.next({ header: 'bf-film-selected', body: { id: body.selected[0].id } })
      },
    }),
  ],
  [
    'navbar-item',
    () => () => ({
      containerClick: ({ dispatcher }) => {
        dispatcher.dispatchEvent(new CustomEvent('container-click-event'))
      },
    }),
  ],
  [
    'navbar',
    (app) => () => ({
      changed: async ({ dispatcher, body }) => {
        let selection = body.selected
        let itemsdatasource = await dispatcher.itemsdatasource

        await storeUpdate({ itemsdatasource, selection })

        app.imports.messenger.next({ header: 'bf-menu-changed' })

        dispatcher.dispatchEvent(
          new CustomEvent('selector-changed-event', {
            detail: body,
          }),
        )
      },
    }),
  ],
])

export { map as default }
