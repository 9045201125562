const initState = {
  models: [
    {
      video: {
        source: './videos/backflip/homepage/home_page.mp4',
        pattern: 'fullscreen-90-zoom',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 1,
    },
  ],

  presentation: [
    {
      model_id: 1,
      id: 1,
      theme: 'backflip-home',
      labelFn: 'uppercase',
    },
  ],
}

export { initState }
