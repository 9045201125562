import frameworkMap from '../../Framework/register/components'
import applicationMap from '../../CustomerApplication/register/components'

const register = (repository) => {
  let map = new Map([...frameworkMap, ...applicationMap])

  map.forEach((value, key) => {
    repository.register({
      name: key,
      initialize: ({ imports }) => {
        let componentThemes = undefined
        let { themes } = imports

        if (themes.get(key) && themes.get(key).module) {
          componentThemes = themes.get(key).module()
        }
        return value(Object.assign(imports, { componentThemes }))
      },
    })
  })

  return repository
}

export { register as default }
