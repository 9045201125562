const initState = {
  models: [
    {
      label: 'CONTACT',
      address: 'Nybohovsbacken 23, 117 63 / Stockholm',
      email: 'dylan@backflipmedia.se',
      id: 1,
    },
  ],

  presentation: [
    {
      model_id: 1,
      id: 1,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
  ],
}

export { initState }
