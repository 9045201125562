export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, transactionalLocalStore } = imports
  let props = () => []

  class BfHomePage extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['X-TEXT', 'X-TEXTGROUP']
    }

    get themes() {
      return componentThemes
    }

    firstUpdated() {
      super.firstUpdated()

      transactionalLocalStore
        .slice('bfHome')
        .collection('models')
        .where('id', '==', 1)
        .get()
        .then((doc) => {
          this.videoData = doc.map((doc) => doc.data())[0].video
          this.requestUpdate()
        })

      this.requestUpdate()
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .video {
              flex: 0 0 auto;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .video {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .video {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .video {
              flex: 0 0 auto;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .video {
              flex: 0 0 auto;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <x-video .data=${this.videoData} class=${classMap({ video: true })}></x-video>
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-home-page`, BfHomePage)
}

//             <source src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4" type="video/mp4" />

// /* .imagebox {
//   height: 100%;
// }

// .image {
//   object-fit: cover;
//   height: 100%;
// } */

// /* .about-main {
//   display: grid;
//   grid-template-columns: minmax(102px, 1fr) 1596px minmax(102px, 1fr);
//   grid-template-rows: auto;
//   grid-template-areas: '. text .';
//   justify-items: end;
//   align-items: end;
//   position: absolute;
//   bottom: 120px;
// }

// .text {
//   grid-area: text;
//   max-width: 621px;
//   color: white;
//   display: none;
// } */

// /* @media screen and (max-width: 1800px) {
//   .about-main {
//     grid-template-columns: 102px minmax(1273px, 1596px) 102px;
//   }
// }

// @media screen and (max-width: 1477px) {
//   .about-main {
//     grid-template-columns: 102px minmax(896px, 1273px) 102px;
//   }
// }

// @media screen and (max-width: 1100px) {
//   .about-main {
//     grid-template-columns: minmax(40px, 102px) 896px minmax(40px, 102px);
//   }
// }

// @media screen and (max-width: 976px) {
//   .about-main {
//     grid-template-columns: 40px minmax(860px, 896px) 40px;
//   }
// }

// @media screen and (max-width: 940px) {
//   .imagebox {
//     position: relative;
//     height: calc(70vh - 100px);
//   }
// } */

// <!-- <div class="imagebox">
//   <img class=${classMap({ image: true })} src=${this.image} />
//   <div class=${classMap({ 'about-main': true })}>
//     <x-textgroup class=${classMap({ text: true })} theme="backflip-about">
//       <x-text theme="backflip-header italic">
//         A great documentary is about discovery, you follow a lead and it takes you in a new direction and by the time you finish, the story is nothing
//         like you imagined...
//       </x-text>
//     </x-textgroup>
//   </div>
// </div> -->
