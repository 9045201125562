class Application {
  constructor() {
    this._modules = new Map()
    this._modulesLoadOrder = []
  }

  get repositories() {
    return this._modules
  }

  get proxy() {
    return new Proxy(this, {
      get: (target, prop) => {
        if (this._modules.has(prop)) {
          return target._modules.get(prop)
        } else {
          try {
            return target[prop]
          } catch (e) {
            console.error('ARGS: ', Array.from(arguments), e)
            throw e
          }
        }
      },
    })
  }

  registerModule(name, module) {
    this._modules.set(name, module)
    this._modulesLoadOrder.push(name)
  }

  initializeAllModules() {
    for (let module of this._modulesLoadOrder) {
      if ('initialize' in this._modules.get(module)) {
        this._modules.get(module).initialize(this.proxy)
      } else {
        console.log(this._modules.get(module))
      }
    }
  }

  initializeModule(module) {
    if ('initialize' in this._modules.get(module)) {
      this._modules.get(module).initialize(this.proxy)
    } else {
      console.log(this._modules.get(module))
    }
  }
}

export { Application as default }
