export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          :host {
            --theme-host-height: 100%;
          }
          * {
            --theme-container-column-gap: var(--spacing-2);
            --theme-container-height: 100%;
          }
        </style>
      `,
    ],
    [
      'backflip',
      html`
        <style>
          :host {
          }
          * {
            --theme-container-column-gap: 66px;
          }

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
              --theme-host-height: 90vh;
            }
            * {
              --theme-container-column-gap: 66px;
              --theme-container-height: 100%;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            :host {
              --theme-host-height: 90vh;
            }
            * {
              --theme-container-column-gap: 66px;
              --theme-container-height: 100%;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            :host {
              --theme-host-height: 90vh;
            }
            * {
              --theme-container-column-gap: 66px;
              --theme-container-height: 100%;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            :host {
              --theme-host-height: 90vh;
            }
            * {
              --theme-container-row-gap: 66px;
              --theme-container-height: 100%;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            :host {
              --theme-host-height: 90vh;
            }
            * {
              --theme-container-row-gap: 66px;
              --theme-container-height: 100%;
            }
          }
        </style>
      `,
    ],
  ])
}
