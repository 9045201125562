export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement } = imports
  let props = () => [{ propKey: 'description', propValue: { type: Object } }]

  class BfDescription extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['X-TEXT', 'X-TEXTGROUP']
      this.layout = 'flex-column'
      this.description = [[]]
    }

    get themes() {
      return componentThemes
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      let flex = html`
        <style>
          .container {
            display: flex;
            flex-direction: ${this.direction == 'row' ? 'row' : 'column'};
            column-gap: var(--theme-container-column-gap);
            row-gap: var(--theme-container-row-gap);
            align-items: var(--theme-container-align-items);
          }
        </style>
      `

      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()} ${this.flex ? flex : ''}
        <style></style>
        <div class=${classMap({ container: true })}>
          ${this.description.map((keyValueArray) => {
            return html`<x-text theme="backflip-description"><b>${keyValueArray[0]}: </b>${keyValueArray[1]}</x-text>`
          })}
          <slot @slotchange=${(e) => this.handleSlotchange(e)}></slot>
          ${this.renderSlotsHTML()}
        </div>
      `
    }
  }

  customElements.define(`bf-description`, BfDescription)
}
