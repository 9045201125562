export default (imports) => {
  let { baseMixin, componentThemes, html, LitElement } = imports
  let props = () => [
    { propKey: 'checked', propValue: { type: Boolean } },
    { propKey: 'disabled', propValue: { type: Boolean } },
    { propKey: 'imageUrl', propValue: { type: String } },
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'theme', propValue: { type: String } },
    { propKey: 'type', propValue: { type: String } },
  ]

  class BfNewsItemgroup extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.checked = false
      this.disabled = false
      this.label = 'No label'
      this.type = 'click'
      this.displayNone = false
    }

    get themes() {
      return componentThemes
    }

    handleClose(e) {
      e.stopPropagation()

      this.displayNone = true
      let event = new CustomEvent('remove-element-event', {})
      this.dispatchEvent(event)
      this.requestUpdate()
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}

        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          }

          /* Wider than iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: flex;
              flex-direction: column;
              row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          }

          /* Wider than iPad Vertical */
          /* @media screen and (min-width: 811px) {
            .container {
              display: flex;
              flex-direction: column;
                 row-gap: 100px;
            }

            ::slotted(*) {
              flex: 0 0 auto;
            }
          } */
        </style>
        <div class="container">
          <slot></slot>
        </div>
      `
    }
  }

  customElements.define(`bf-news-itemgroup`, BfNewsItemgroup)
}
