export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          :host {
            --theme-host-height: 100%;
            --theme-host-min-width: 0px;
          }

          * {
            --theme-container-align-items: center;
            --theme-container-justify-content: center;
            --theme-container-transition: all 0.2s;
            --theme-container-background-color: var(--color-transparent);

            --theme-item-background-color: var(--color-transparent);

            --theme-item-background-color--hover: var(--color-transparent);
            --theme-item-background-color--checked: var(--color-transparent);
            --theme-item-background-color--disabled: var(--color-transparent);
            --theme-item-border-bottom-width: 0px;
            --theme-item-border-color: var(--color-transparent);
            --theme-item-border-color--hover: var(--color-transparent);
            --theme-item-border-color--checked: var(--color-transparent);
            --theme-item-border-color--disabled: var(--color-transparent);
            --theme-item-border-left-width: 0px;
            --theme-item-border-radius: 0px;
            --theme-item-border-right-width: 0px;
            --theme-item-border-style: solid;
            --theme-item-border-top-width: 0px;
            --theme-item-border-width: 0px;
            --theme-item-color: var(--color-white);
            --theme-item-color--hover: var(--color-white);
            --theme-item-color--checked: var(--color-white);
            --theme-item-color--disabled: var(--color-white);
            --theme-item-padding: 0 calc(var(--spacing-5) - 1px);
            --theme-icon-hide-display: none;
            /* --theme-container-height: 100%; */

            /* --theme-container-opacity--disabled: 0.1; */
          }
        </style>
      `,
    ],
    [
      'backflip-menu',
      html`
        <style>
          /* :host {
          } */

          * {
            /* --theme-item-color: var(--color-black);
            --theme-item-color--hover: var(--color-black);
            --theme-item-color--checked: var(--color-black);
            --theme-item-border-bottom-width: 2px;
            --theme-item-border-color: var(-color-transparent);
            --theme-item-border-color--hover: var(--color-black);
            --theme-item-border-color--checked: var(--color-black);
            --theme-item-padding: 6px 0 4px 0; */
          }

          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
            }
            * {
              --theme-item-color: var(--color-black);
              --theme-item-color--hover: var(--color-black);
              --theme-item-color--checked: var(--color-black);
              --theme-item-border-bottom-width: 2px;
              --theme-item-border-color: var(-color-transparent);
              --theme-item-border-color--hover: var(--color-black);
              --theme-item-border-color--checked: var(--color-black);
              --theme-item-padding: 6px 0 4px 0;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            :host {
            }
            * {
              --theme-item-color: var(--color-black);
              --theme-item-color--hover: var(--color-black);
              --theme-item-color--checked: var(--color-black);
              --theme-item-border-bottom-width: 2px;
              --theme-item-border-color: var(-color-transparent);
              --theme-item-border-color--hover: var(--color-black);
              --theme-item-border-color--checked: var(--color-black);
              --theme-item-padding: 6px 0 4px 0;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            :host {
            }
            * {
              --theme-item-color: var(--color-black);
              --theme-item-color--hover: var(--color-black);
              --theme-item-color--checked: var(--color-black);
              --theme-item-border-bottom-width: 2px;
              --theme-item-border-color: var(-color-transparent);
              --theme-item-border-color--hover: var(--color-black);
              --theme-item-border-color--checked: var(--color-black);
              --theme-item-padding: 6px 0 4px 0;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            :host {
            }
            * {
              --theme-item-color: var(--color-black);
              --theme-item-color--hover: var(--color-black);
              --theme-item-color--checked: var(--color-black);
              --theme-item-border-bottom-width: 2px;
              --theme-item-border-color: var(-color-transparent);
              --theme-item-border-color--hover: var(--color-black);
              --theme-item-border-color--checked: var(--color-black);
              --theme-item-padding: 6px 0 4px 0;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            :host {
            }
            * {
              --theme-item-color: var(--color-black);
              --theme-item-color--hover: var(--color-black);
              --theme-item-color--checked: var(--color-black);
              --theme-item-border-bottom-width: 2px;
              --theme-item-border-color: var(-color-transparent);
              --theme-item-border-color--hover: var(--color-black);
              --theme-item-border-color--checked: var(--color-black);
              --theme-item-padding: 6px 0 4px 0;
            }
          }
        </style>
      `,
    ],
    [
      'primary',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: var(--color-yellow);
            --theme-container-background-color--hover: var(--color-yellow-dark);
            --theme-container-background-color--checked: var(--color-yellow-darker);
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: var(--color-yellow-dark);
            --theme-container-border-color--hover: var(--color-yellow-darker);
            --theme-container-border-color--checked: var(--color-yellow-darker);
            --theme-container-border-color--disabled: var(--color-black);
            --theme-container-color: var(--color-black);
            --theme-container-color--hover: var(--color-black);
            --theme-container-color--checked: var(--color-black);
            --theme-container-color--disabled: var(--color-black);
          }
        </style>
      `,
    ],
    [
      'secondary',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: var(--color-beige);
            --theme-container-background-color--hover: var(--color-beige-dark);
            --theme-container-background-color--checked: var(--color-beige-darker);
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: var(--color-beige-dark);
            --theme-container-border-color--hover: var(--color-beige-darker);
            --theme-container-border-color--checked: var(--color-beige-darker);
            --theme-container-border-color--disabled: var(--color-black);
            --theme-container-color: var(--color-black);
            --theme-container-color--hover: var(--color-black);
            --theme-container-color--checked: var(--color-black);
            --theme-container-color--disabled: var(--color-black);
          }
        </style>
      `,
    ],
    [
      'tertiary',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: none;
            --theme-container-background-color--hover: none;
            --theme-container-background-color--checked: none;
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: var(--color-black);
            --theme-container-border-color--hover: var(--color-gray-darkest);
            --theme-container-border-color--checked: var(--color-gray-darker);
            --theme-container-border-color--disabled: var(--color-black);
            --theme-container-color: var(--color-black);
            --theme-container-color--hover: var(--color-gray-darkest);
            --theme-container-color--checked: var(--color-black);
            --theme-container-color--disabled: var(--color-black);
          }
        </style>
      `,
    ],
    [
      'tertiary-light',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: none;
            --theme-container-background-color--hover: none;
            --theme-container-background-color--checked: none;
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: var(--color-white);
            --theme-container-border-color--hover: var(--color-gray-light);
            --theme-container-border-color--checked: var(--color-gray-dark);
            --theme-container-border-color--disabled: var(--color-white);
            --theme-container-color: var(--color-white);
            --theme-container-color--hover: var(--color-gray-dark);
            --theme-container-color--checked: var(--color-gray-darkest);
            --theme-container-color--disabled: var(--color-white);
          }
        </style>
      `,
    ],
    [
      'link',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: none;
            --theme-container-background-color--hover: none;
            --theme-container-background-color--checked: none;
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: none;
            --theme-container-border-color--hover: none;
            --theme-container-border-color--checked: none;
            --theme-container-border-color--disabled: none;
            --theme-container-color: var(--color-black);
            --theme-container-color--hover: var(--color-gray-darkest);
            --theme-container-color--checked: var(--color-gray-dark);
            --theme-container-color--disabled: var(--color-black);
          }
        </style>
      `,
    ],
    [
      'link-light',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-background-color: none;
            --theme-container-background-color--hover: none;
            --theme-container-background-color--checked: none;
            --theme-container-background-color--disabled: none;
            --theme-container-border-color: none;
            --theme-container-border-color--hover: none;
            --theme-container-border-color--checked: none;
            --theme-container-border-color--disabled: none;
            --theme-container-color: var(--color-white);
            --theme-container-color--hover: var(--color-gray-dark);
            --theme-container-color--checked: var(--color-gray-darkest);
            --theme-container-color--disabled: var(--color-white);
          }
        </style>
      `,
    ],
  ])
}
