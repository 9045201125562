const eventMixin = ({ imports }) => () => (superClass) => {
  let { R, rxHelpers } = imports
  return class EventMixin extends superClass {
    disconnectedCallback() {
      if (this.unsubscribe != undefined) {
        this.unsubscribe()
      }
      super.disconnectedCallback()
    }

    eventHandlerRegistrar(target, eventName, handler) {
      let unsubscribe = () => target.removeEventListener(eventName, handler)
      target.addEventListener(eventName, handler)
      return unsubscribe
    }
  }
}

export { eventMixin as default }
