import bfAboutPage from '../components/bf-about-page/bf-about-page'
import bfDescription from '../components/bf-description/bf-description'
import bfFilmPage from '../components/bf-film-page/bf-film-page'
import bfFilmsItem from '../components/bf-films-item/bf-films-item'
import bfFilmsItemgroup from '../components/bf-films-itemgroup/bf-films-itemgroup'
import bfFilmsPage from '../components/bf-films-page/bf-films-page'
import bfFooter from '../components/bf-footer/bf-footer'
import bfHeader from '../components/bf-header/bf-header'
import bfHomePage from '../components/bf-home-page/bf-home-page'
import bfInproductionItem from '../components/bf-inproduction-item/bf-inproduction-item'
import bfInproductionBItem from '../components/bf-inproduction-b-item/bf-inproduction-b-item'
import bfInproductionItemgroup from '../components/bf-inproduction-itemgroup/bf-inproduction-itemgroup'
import bfInproductionPage from '../components/bf-inproduction-page/bf-inproduction-page'
import bfNewsItem from '../components/bf-news-item/bf-news-item'
import bfNewsItemgroup from '../components/bf-news-itemgroup/bf-news-itemgroup'
import bfNewsPage from '../components/bf-news-page/bf-news-page'

let map = new Map([
  ['bf-about-page', bfAboutPage],
  ['bf-description', bfDescription],
  ['bf-film-page', bfFilmPage],
  ['bf-films-item', bfFilmsItem],
  ['bf-films-itemgroup', bfFilmsItemgroup],
  ['bf-films-page', bfFilmsPage],
  ['bf-footer', bfFooter],
  ['bf-header', bfHeader],
  ['bf-home-page', bfHomePage],
  ['bf-inproduction-item', bfInproductionItem],
  ['bf-inproduction-b-item', bfInproductionBItem],
  ['bf-inproduction-itemgroup', bfInproductionItemgroup],
  ['bf-inproduction-page', bfInproductionPage],
  ['bf-news-item', bfNewsItem],
  ['bf-news-itemgroup', bfNewsItemgroup],
  ['bf-news-page', bfNewsPage],
])

export { map as default }
