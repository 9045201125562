import customerSlices from '../CustomerApplication/slices'
import frameworkSlices from '../Framework/slices'

let action = Object.assign(frameworkSlices.action, { ...customerSlices.action })
let reducer = Object.assign(frameworkSlices.reducer, { ...customerSlices.reducer })

let slices = {
  action,
  reducer,
}

export { slices as default }
