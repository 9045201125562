//BACKFLIP

import application from '../../customer-application'
;(async () => {
  let app = await application

  let { baseMixin, browserChecker, classMap, html, LitElement, messenger, Router, themes, transactionalLocalStore } = app.imports

  let props = () => []

  class CustomerApp extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.routes = new Map([
        [
          '#',
          {
            slice: 'bfMenu',
            property: 'value',
            children: () =>
              new Map([
                [
                  'home',
                  {
                    component: () =>
                      html`<bf-home-page .data=${{ image: './images/home_bg.jpg' }} class=${classMap({ 'home-page-main': true })}> </bf-home-page>`,
                  },
                ],
                [
                  'about',
                  {
                    component: () =>
                      html`<bf-about-page
                        .data=${{ backgroundimage: { source: './images/dylan.png', pattern: 'proportional-height' } }}
                        class=${classMap({ main: true })}
                      >
                      </bf-about-page>`,
                  },
                ],
                [
                  'films',
                  {
                    component: () => html`<bf-films-page class=${classMap({ main: true })}> </bf-films-page>`,
                    slice: 'bfFilms',
                    property: 'id',
                    children: () =>
                      new Map([
                        [
                          '*',
                          {
                            component: (hash) => html`<bf-film-page .hash=${hash} class=${classMap({ 'film-page-main': true })}> </bf-film-page>`,
                          },
                        ],
                      ]),
                  },
                ],
                [
                  'in-production',
                  {
                    component: () => html`<bf-inproduction-page class=${classMap({ main: true })}> </bf-inproduction-page>`,
                  },
                ],
                [
                  'news',
                  {
                    component: () =>
                      html`<bf-news-page
                        .data=${{ backgroundimage: { source: './images/dylan.png', pattern: 'proportional-height' } }}
                        class=${classMap({ main: true })}
                      >
                      </bf-news-page>`,
                  },
                ],
              ]),
          },
        ],
      ])
    }

    get themes() {
      return themes.get('customer-app').module()
    }

    firstUpdated() {
      super.firstUpdated()
      window.history.pushState({}, '', `/#/home`)
      let outlet = this.shadowRoot.querySelector('#outlet')
      this.navigator = new Router(this, outlet)
      this.navigator.setRoutes(this.routes)

      messenger.subscribe(async (message) => {
        if (message && message.header == 'bf-menu-changed') {
          this.navigator.updateLocation(['#'])
        }
      })

      messenger.subscribe(async (message) => {
        if (message && message.header == 'bf-film-selected') {
          this.navigator.updateLocation(['#', 'films'])
        }
      })
    }

    render() {
      if (this.shadowRoot.querySelector('#container')) {
        this.shadowRoot.querySelector('#container').scrollTop = 0
      }

      this.hashLevel = this.navigator ? this.navigator.hashArrayFromLoaction() : []
      if (
        !(browserChecker.isChrome || browserChecker.isEdge || browserChecker.isSafari || browserChecker.isFirefox || browserChecker.isMobileSafari || false)
      ) {
        return html`Use Chrome`
      } else {
        return html`
          ${this.themeStyles}
          <style>
            /* iPhone 12 Pro Max Vertical */
            @media screen and (min-width: 0px) and (max-width: 428px) {
              :host {
                --header-height: 100px;
                --footer-height: 288px;
              }

              .wrapper {
                display: flex;
                position: relative;
              }

              .container {
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .header {
                position: fixed;
                z-index: 3;
                top: 0;
                left: 0;
                right: 0;
              }

              .main {
                flex: 1 1 auto;
              }

              .footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .film-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .film-page-main {
                flex: 1 0 auto;
                /* top: -100px; */
              }

              .film-page-footer {
                flex: 0 0 var(--footer-height);
                /* top: -100px; */
              }

              .home-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .home-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .home-page-main {
                flex: 1 0 auto;
                top: -100px;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
                top: -100px;
              }
            }

            /* iPad Vertical */
            @media screen and (min-width: 429px) and (max-width: 810px) {
              :host {
                --header-height: 100px;
                --footer-height: 288px;
              }

              .wrapper {
                display: flex;
                position: relative;
              }

              .container {
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .header {
                position: fixed;
                z-index: 3;
                top: 0;
                left: 0;
                right: 0;
              }

              .main {
                flex: 1 1 auto;
              }

              .footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-container {
                display: flex;
                flex-direction: column;
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .film-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .film-page-main {
                flex: 1 0 auto;
                /* top: -100px; */
              }

              .film-page-footer {
                flex: 0 0 var(--footer-height);
                /* top: -100px; */
              }

              .home-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .home-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .home-page-main {
                flex: 1 0 auto;
                top: -100px;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
                top: -100px;
              }
            }

            /* iPad Pro 12.9 Vertical */
            @media screen and (min-width: 811px) and (max-width: 1024px) {
              :host {
                --header-height: 100px;
                --footer-height: 288px;
              }

              .wrapper {
                display: flex;
                position: relative;
              }

              .container {
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .header {
                position: fixed;
                z-index: 3;
                top: 0;
                left: 0;
                right: 0;
              }

              .main {
                flex: 1 1 auto;
              }

              .footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .film-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .film-page-main {
                flex: 1 0 auto;
                /* top: -100px; */
              }

              .film-page-footer {
                flex: 0 0 var(--footer-height);
                /* top: -100px; */
              }

              .home-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: calc(100vh - var(--header-height));
                width: 100vw;
                overflow-y: scroll;
                position: relative;
                top: var(--header-height);
              }

              .home-page-header {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .home-page-main {
                flex: 1 0 auto;
                top: -100px;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
                top: -100px;
              }
            }

            /* iPad Pro 12.9 Horizontal */
            @media screen and (min-width: 1025px) and (max-width: 1366px) {
              :host {
                --header-height: 100px;
                --footer-height: 288px;
              }

              .wrapper {
                display: flex;
                position: relative;
              }

              .container {
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .header {
                flex: 0 0 var(--header-height);
              }

              .main {
                flex: 1 1 auto;
              }

              .footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .film-page-header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .film-page-main {
                flex: 1 0 auto;
              }

              .film-page-footer {
                flex: 0 0 var(--footer-height);
              }

              .home-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .home-page-header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .home-page-main {
                flex: 1 0 auto;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
              }
            }

            /* Wider then iPad Pro 12.9 Horizontal */
            @media screen and (min-width: 1367px) {
              :host {
                --header-height: 100px;
                --footer-height: 288px;
              }

              .wrapper {
                display: flex;
                position: relative;
              }

              .container {
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .header {
                flex: 0 0 var(--header-height);
              }

              .main {
                flex: 1 1 auto;
              }

              .footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-footer {
                flex: 0 0 var(--footer-height);
              }

              .film-page-container {
                display: flex;
                flex-direction: column;
                /* background-color: var(--color-white); */
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .film-page-header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .film-page-main {
                flex: 1 0 auto;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
              }

              .home-page-container {
                display: flex;
                flex-direction: column;
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                position: relative;
              }

              .home-page-header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10000;
              }

              .home-page-main {
                flex: 1 0 auto;
              }

              .home-page-footer {
                flex: 0 0 var(--footer-height);
              }
            }
          </style>
          <div class="wrapper">
            <div
              id="container"
              class=${classMap({
                container: this.hashLevel[1] != 'home' || (this.hashLevel[1] != 'films' && this.hashLevel[2] != undefined),
                'home-page-container': this.hashLevel[1] == 'home',
                'film-page-container': this.hashLevel[1] == 'films' && this.hashLevel[2] != undefined,
              })}
            >
              <bf-header
                class=${classMap({
                  header: this.hashLevel[1] != 'home' || (this.hashLevel[1] != 'films' && this.hashLevel[2] != undefined),
                  'home-page-header': this.hashLevel[1] == 'home',
                  'film-page-header': this.hashLevel[1] == 'films' && this.hashLevel[2] != undefined,
                })}
              ></bf-header>
              <div id="outlet"></div>
              <bf-footer
                class=${classMap({
                  footer: this.hashLevel[1] != 'home' || (this.hashLevel[1] != 'films' && this.hashLevel[2] != undefined),
                  'home-page-footer': this.hashLevel[1] == 'home',
                  'film-page-footer': this.hashLevel[1] == 'films' && this.hashLevel[2] != undefined,
                })}
                .itemsdatasource=${{
                  store: transactionalLocalStore,
                  models: 'models',
                  presentations: 'presentation',
                  slice: 'bfFooter',
                }}
                fetchStrategyName="slice"
                renderItemsStrategyName="bf-footer-item"
              ></bf-footer>
            </div>
          </div>
        `
      }
    }
  }

  customElements.define(`customer-app`, CustomerApp)
})()
