class Repository extends Map {
  initialize(app) {
    this.app = app
    this.initializeAllRegisteredItems()
  }

  static get [Symbol.species]() {
    return Map
  }

  initializeAllRegisteredItems() {
    this.forEach((value) => {
      if (value.initialize) {
        value.module = value.initialize(this.app)
      }

      if (value.module && 'initialize' in value.module) {
        value.module.initialize(this.app)
      }
    })
  }

  register(type) {
    this.set(type.name, type)
  }
}

export { Repository as default }
