let props = () => [
  { propKey: 'dispatchStrategy', propValue: { type: Object } },
  { propKey: 'error', propValue: { type: Boolean } },
  { propKey: 'focus', propValue: { type: Boolean } },
  { propKey: 'placeholder', propValue: { type: String } },
  { propKey: 'prefix', propValue: { type: String } },
  { propKey: 'readOnly', propValue: { type: Boolean } },
  { propKey: 'slotNames', propValue: { type: String } },
  { propKey: 'span', propValue: { type: String } },
  { propKey: 'suffix', propValue: { type: String } },
  { propKey: 'themes', propValue: { type: Object } },
  { propKey: 'value', propValue: { type: String } },
]

export default ({ imports }) => () => (baseMixin, superClass) => {
  return class InputItem extends baseMixin(props, superClass) {
    constructor(dispatchStrategies) {
      super()
      this.checked = false
      this.id = undefined
      this.indeterminate = false
      this.label = 'No label'
      this.placeholder = 'Placeholder...'
      this.value = ''
      this.checked = false
      this.direction = 'row'
      this.icon = false
      this.iconName = 'no-icon'
      this.iconProvider = 'no-provider'
      this.dispatchStrategy = dispatchStrategies.get('input-item').module()
    }

    handleChange(e) {
      e.stopPropagation()
      this.dispatchStrategy.changed({ dispatcher: this, event: e })
      this.requestUpdate()
    }

    handleInput(e) {
      e.stopPropagation()
      this.dispatchStrategy.input({ dispatcher: this, event: e })
      this.requestUpdate()
    }

    // text(label) {
    //   return html`<div class=${classMap({ label: true })}>
    //     <x-text theme="regular-2 regular-2-center">${label}</x-text>
    //   </div>`
    // }

    // toRender({ icon, input, text }) {
    //   return html` <div class=${classMap({ container: true, checked: this.checked, indeterminate: this.indeterminate })}>
    //     <div class=${classMap({ checkbox: true })}>${input()} ${icon()}</div>
    //     ${text} ${this.renderSlotsHTML()}
    //   </div>`
    // }

    // render({ icon, input, text }) {
    //   return this.toRender({ icon, input, text })
    // }
  }
}
