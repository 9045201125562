import { css } from 'lit-element'

const base = css`
  :host {
    display: block;
    position: relative;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    margin: 0px;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    // margin: 0px;
    -webkit-tap-highlight-color: transparent;
  }
`

export { base as default }
