export default (imports) => {
  let { html, componentThemes, baseMixin, classMap, LitElement } = imports
  let props = () => [
    { propKey: 'checked', propValue: { type: Boolean } },
    { propKey: 'imageUrl', propValue: { type: String } },
    { propKey: 'label', propValue: { type: String } },
    { propKey: 'theme', propValue: { type: String } },
    { propKey: 'type', propValue: { type: String } },
  ]

  class BfInproductionItem extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.checked = false
      this.disabled = false
      this.label = 'No label'
      this.type = 'click'
      this.displayNone = false
      this.paragraphs = []
    }

    get themes() {
      return componentThemes
    }

    handleClose(e) {
      e.stopPropagation()

      this.displayNone = true
      let event = new CustomEvent('remove-element-event', {})
      this.dispatchEvent(event)
      this.requestUpdate()
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            .container {
              display: grid;
              grid-template-columns: 40px 0px 1fr 0px 40px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: 1fr;
              grid-template-rows: auto;
              grid-template-areas:
                'imagebox'
                'about-main';
              row-gap: 24px;
            }

            .imagebox {
              grid-area: imagebox;
            }

            .about-main {
              grid-area: about-main;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: grid;
              grid-template-columns: 60px 0px 1fr 0px 60px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: 5fr 1fr 7fr;
              grid-template-rows: auto;
              grid-template-areas: 'imagebox . about-main';
              max-width: 1007px;
              justify-self: start;
            }

            .imagebox {
              grid-area: imagebox;
            }

            .about-main {
              grid-area: about-main;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: 5fr 1fr 7fr;
              grid-template-rows: auto;
              grid-template-areas: 'imagebox . about-main';
              max-width: 1007px;
              justify-self: start;
            }

            .imagebox {
              grid-area: imagebox;
            }

            .about-main {
              grid-area: about-main;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: grid;
              grid-template-columns: 80px 0px 1fr 0px 80px;
              grid-template-rows: auto;
              grid-template-areas: '. . main . .';
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: 5fr 1fr 7fr;
              grid-template-rows: auto;
              grid-template-areas: 'imagebox . about-main';
              max-width: 1007px;
              justify-self: start;
            }

            .imagebox {
              grid-area: imagebox;
            }

            .about-main {
              grid-area: about-main;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: grid;
              grid-template-columns: 1fr 80px 0px 1207px 0px 80px 1fr;
              grid-template-rows: auto;
              grid-template-areas: '. . . main . . .';
            }

            .main {
              grid-area: main;
              display: grid;
              grid-template-columns: 5fr 1fr 7fr;
              grid-template-rows: auto;
              grid-template-areas: 'imagebox . about-main';
              max-width: 1007px;
              justify-self: start;
            }

            .imagebox {
              grid-area: imagebox;
            }

            .about-main {
              grid-area: about-main;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }
          }
        </style>
        <div class="container">
          <div class="main">
            <x-image class=${classMap({ imagebox: true })} .data=${this.posterimage}></x-image>
            <div class=${classMap({ 'about-main': true })}>
              <x-text theme="backflip-header">${this.label}</x-text>
              <bf-description .data=${{ description: this.description }} theme="backflip-film-description"></bf-description>
              <x-textgroup theme="backflip-about">
                ${this.paragraphs.map((paragraph) => {
                  return html` <x-text theme="backflip-body"> ${paragraph} </x-text>`
                })}
              </x-textgroup>
            </div>
          </div>
        </div>
      `
    }
  }

  customElements.define(`bf-inproduction-item`, BfInproductionItem)
}

// <img class=${classMap({ imagebox: true })} src=${this.image} />
