export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, transactionalLocalStore } = imports
  let props = () => []

  class BfNewsPage extends baseMixin(props, LitElement) {
    constructor() {
      super()
    }

    get themes() {
      return componentThemes
    }

    render() {
      return html`
        ${this.themeStyles}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              flex-direction: column;
              padding-bottom: 0px;
              padding-top: 76px;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* Wider than iPad Vertical */
          /* @media screen and (min-width: 811px) {
            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }


            .main {
              flex: 0 0 auto;
            }
          } */
        </style>
        <div class=${classMap({ container: true })}>
          <bf-news-itemgroup
            class=${classMap({ main: true })}
            .itemsdatasource=${{
              store: transactionalLocalStore,
              models: 'models',
              presentations: 'presentation',
              slice: 'bfNews',
            }}
            fetchStrategyName="slice"
            renderItemsStrategyName="bf-news-item"
            theme="backflip"
          ></bf-news-itemgroup>
        </div>
      `
    }
  }

  customElements.define(`bf-news-page`, BfNewsPage)
}

// <div class=${classMap({ imagebox: true })}><img class=${classMap({ image: true })} src=${this.image} /></div>
