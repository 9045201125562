import bfFooterSlice from './slices/footer/slice'
import bfFilmsSlice from './slices/films/slice'
import bfHomeSlice from './slices/home/slice'
import bfMenuSlice from './slices/menu/slice'
import bfNewsSlice from './slices/news/slice'

let action = {
  bfFilms: bfFilmsSlice.actions,
  bfFooter: bfFooterSlice.actions,
  bfHome: bfHomeSlice.actions,
  bfMenu: bfMenuSlice.actions,
  bfNews: bfNewsSlice.actions,
}

let reducer = {
  bfFilms: bfFilmsSlice.reducer,
  bfFooter: bfFooterSlice.reducer,
  bfHome: bfHomeSlice.reducer,
  bfMenu: bfMenuSlice.reducer,
  bfNews: bfNewsSlice.reducer,
}

let slices = {
  action,
  reducer,
}

export { slices as default }
