let commonProperties = () => [
  //themeMixin
  { propKey: 'theme', propValue: { type: String } },
  { propKey: 'themes', propValue: { type: Object } },
  { propKey: 'themesName', propValue: { type: String } },
  { propKey: 'themeStyles', propValue: { type: Object } },
  //
  //iconsMixin
  { propKey: 'icon', propValue: { type: String } },
  { propKey: 'icons', propValue: { type: Object } },
  { propKey: 'iconStyles', propValue: { type: Object } },
  //
  //slotMixin
  { propKey: 'slotNames', propValue: { type: String } },
  //
  //datapropertyMixin
  { propKey: 'data', propValue: { type: Object } },
  //
  //orderMixin
  { propKey: 'first', propValue: { type: Boolean } },
  { propKey: 'last', propValue: { type: Boolean } },
  //
  //layoutMixin
  { propKey: 'columns', propValue: { type: String } },
  { propKey: 'direction', propValue: { type: String } },
  { propKey: 'flex', propValue: { type: String } },
  { propKey: 'grid', propValue: { type: String } },
  { propKey: 'layout', propValue: { type: String } },
  //TODO: Only used in X-Spinn, X-Rate-Item, X-Datacell, X-Modal
  { propKey: 'col', propValue: { type: String } },
  { propKey: 'span', propValue: { type: String } },
  //TODO: Only used in X-Section-Overlay
  { propKey: 'flexbasis', propValue: { type: String } },
  { propKey: 'flexgrow', propValue: { type: String } },
  { propKey: 'flexshrink', propValue: { type: String } },
  //
  //eventMixin
  { propKey: 'unsubscribe', propValue: { type: Object } },
  //

  //TODO: MAKE MIXIN FOR STRATEGIES
  { propKey: 'dispatchStrategy', propValue: { type: Object } },
  { propKey: 'dispatchStrategyName', propValue: { type: String } },
  { propKey: 'fetchStrategy', propValue: { type: Object } },
  { propKey: 'fetchStrategyName', propValue: { type: String } },
  { propKey: 'queryStrategy', propValue: { type: Object } },
  { propKey: 'queryStrategyName', propValue: { type: String } },
  { propKey: 'renderItemsStrategy', propValue: { type: Object } },
  { propKey: 'renderItemsStrategyName', propValue: { type: String } },
  { propKey: 'itemsdatasource', propValue: { type: Object } },
  { propKey: 'childTags', propValue: { type: Object } },
  { propKey: 'id', propValue: { type: Number } },
]

const baseMixin = ({ imports }) => () => (props, superClass) => {
  let {
    baseCss,
    datapropertyMixin,
    dispatchStrategies,
    eventMixin,
    fetchStrategies,
    gridCss,
    iconsMixin,
    layoutMixin,
    orderMixin,
    queryStrategies,
    renderStrategies,
    rxHelpers,
    rxMixin,
    slotMixin,
    themeMixin,
  } = imports
  let concatinatedProperties = () => [...props(), ...commonProperties()]
  return class baseClass extends slotMixin(iconsMixin(datapropertyMixin(eventMixin(orderMixin(layoutMixin(themeMixin(rxMixin(concatinatedProperties, superClass))))))),
  ) {
    static get properties() {
      return concatinatedProperties().reduce((acc, prop) => {
        return { ...acc, [prop.propKey]: prop.propValue }
      }, {})
    }

    static get styles() {
      return [baseCss, gridCss]
    }

    firstUpdated() {
      super.firstUpdated()

      rxHelpers
        .combineLatest([this.dispatchStrategyName$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(async () => {
          this.dispatchStrategy = dispatchStrategies.get(this.dispatchStrategyName).module()
        })

      rxHelpers
        .combineLatest([this.fetchStrategyName$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(async () => {
          this.fetchStrategy = fetchStrategies.get(this.fetchStrategyName).module(imports)()
        })

      rxHelpers
        .combineLatest([this.queryStrategyName$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(async () => {
          this.queryStrategy = queryStrategies.get(this.queryStrategyName).module()
        })

      rxHelpers
        .combineLatest([this.renderItemsStrategyName$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(async () => {
          this.renderItemsStrategy = renderStrategies.get(this.renderItemsStrategyName).module(imports)()
        })

      rxHelpers
        .combineLatest([this.itemsdatasource$, this.fetchStrategy$, this.renderItemsStrategy$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(async () => {
          this.renderItemsStrategy({
            component: this,
            fetch: this.fetchStrategy({
              datasource: await this.itemsdatasource,
              component: this,
            }),
          })
        })
    }
  }
}

export { baseMixin as default }

// get props() {
//   return concatinatedProperties()
// }

// getParentDirection(node) {
//   if (this.direction != undefined) {
//     node.parentDirection = this.direction
//   }
// }

// inheritProperties(node, config) {
//   if (node.props && node.props.length) {
//     node.props
//       .map((prop) => prop.propKey)
//       .forEach((propertyName) => {
//         if (config.inheritPropertyValues == true) {
//           //TODO: SHOULD WE IMPLEMENT THIS?
//           if (propertyName == 'theme') {
//             if (config.keepOwnPropertyValuesIfSet == true) {
//               if (node.attributes && node.attributes.getNamedItem(propertyName)) {
//                 node[propertyName] = node.attributes.getNamedItem(propertyName).value
//               } else {
//                 node[propertyName] = this[propertyName]
//               }
//             } else {
//               node[propertyName] = this[propertyName]
//             }
//           }
//           //TODO: SHOULD WE IMPLEMENT THIS?
//           if (propertyName == 'direction') {
//             if (config.keepOwnPropertyValuesIfSet == true) {
//               if (node.attributes && node.attributes.getNamedItem(propertyName)) {
//                 node[propertyName] = node.attributes.getNamedItem(propertyName).value
//               } else {
//                 node[propertyName] = this[propertyName]
//               }
//             } else {
//               node[propertyName] = this[propertyName]
//             }
//           }
//         }
//       })
//   }
// }

// prepareNodes(nodes) {
//       if (super.prepareNodes) {
//         super.prepareNodes(nodes)
//       }
//       nodes.forEach((node, i) => {
//         this.addOrderAttributes(node, nodes.length, i)
//         this.addOrderClassNames(node, nodes.length, i)
//         // this.addGridClassNames(node, nodes.length, i)

//         //TODO: SHOULD WE IMPLEMENT THIS?
//         //this.inheritProperties(node, overridePredicate)
//         // this.getParentDirection(node)
//         // node.addEventListener('slotchange', (e) => node.slotchangeHandler(e))
//       })
//     }
