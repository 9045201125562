export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
            --theme-container-padding-bottom: 0px;
            --theme-container-top: 0px;
          }
        </style>
      `,
    ],
    [
      'backflip-body',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-eb-garamond);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'backflip-body-large',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-eb-garamond);
            --theme-container-font-size: var(--font-size-lg);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-lg);
          }
        </style>
      `,
    ],
    [
      'backflip-description',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-sm);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'backflip-description-bold',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-sm);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'backflip-header',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-eb-garamond);
            --theme-container-font-size: var(--font-size-xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-xl);
          }
        </style>
      `,
    ],
    [
      'backflip-header-large',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-eb-garamond);
            --theme-container-font-size: var(--font-size-2xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-2xl);
          }
        </style>
      `,
    ],
    [
      'backflip-logo',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-voir-48);
            --theme-container-font-size: var(--font-size-2xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
          }
        </style>
      `,
    ],
    [
      'backflip-logo-large',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-voir-48);
            --theme-container-font-size: var(--font-size-5xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-5xl);
            --theme-container-top: 14px;
          }
        </style>
      `,
    ],
    [
      'backflip-menu',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
            --theme-container-white-space: nowrap;
            /* --theme-container-line-height: var(--font-line-height-sm); */
          }
        </style>
      `,
    ],
    [
      'backflip-menu-bold',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
            --theme-container-white-space: nowrap;
          }
        </style>
      `,
    ],
    [
      'backflip-footer',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
          }
        </style>
      `,
    ],
    [
      'backflip-footer-bold',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-lato);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
          }
        </style>
      `,
    ],
    [
      'bold-1',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-3xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: var(--font-letter-spacing-2xs);
            --theme-container-line-height: var(--font-line-height-2xl);
          }
        </style>
      `,
    ],
    [
      'bold-2',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-2xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: var(--font-letter-spacing-xs);
            --theme-container-line-height: var(--font-line-height-xl);
          }
        </style>
      `,
    ],
    [
      'bold-3',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: var(--font-letter-spacing-sm);
            --theme-container-line-height: var(--font-line-height-lg);
          }
        </style>
      `,
    ],
    [
      'bold-4',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-lg);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'bold-5',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'bold-6',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-sm);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-bold);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
          }
        </style>
      `,
    ],
    [
      'regular-1',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-exo-2);
            --theme-container-font-size: var(--font-size-xl);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'regular-menuitem',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-exo-2);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'regular-formboxlabel',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-sm);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-medium);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'regular-filterheader',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-medium);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'regular-2',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-base);
          }
        </style>
      `,
    ],
    [
      'regular-3',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-roboto);
            --theme-container-font-size: var(--font-size-sm);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: var(--font-line-height-sm);
          }
        </style>
      `,
    ],
    [
      'bold',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-weight: var(--font-weight-bold);
          }
        </style>
      `,
    ],
    [
      'logo',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-exo-2);
            --theme-container-font-size: var(--font-size-base);
            --theme-container-font-style: var(--font-style-not-italic);
            --theme-container-font-weight: var(--font-weight-normal);
            --theme-container-letter-spacing: unset;
            --theme-container-line-height: unset;
          }
        </style>
      `,
    ],
    [
      'no-lineheight',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: unset;
          }
        </style>
      `,
    ],
    [
      'bold-1-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-3xl);
          }
        </style>
      `,
    ],
    [
      'bold-2-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-2xl);
          }
        </style>
      `,
    ],
    [
      'bold-3-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-xl);
          }
        </style>
      `,
    ],
    [
      'bold-3-header',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-padding-bottom: var(--spacing-3);
          }
        </style>
      `,
    ],
    [
      'bold-4-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-lg);
          }
        </style>
      `,
    ],
    [
      'bold-5-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-base);
          }
        </style>
      `,
    ],
    [
      'bold-5-header',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-padding-bottom: var(--spacing-0);
          }
        </style>
      `,
    ],
    [
      'bold-6-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-sm);
          }
        </style>
      `,
    ],
    [
      'regular-1-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-lg);
          }
        </style>
      `,
    ],
    [
      'regular-2-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-base);
          }
        </style>
      `,
    ],
    [
      'regular-3-center',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-line-height: var(--font-size-sm);
            --theme-container-top: 1px;
          }
        </style>
      `,
    ],
    [
      'exo',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-family: var(--font-family-exo-2);
          }
        </style>
      `,
    ],
    [
      'italic',
      html`
        <style>
          /* :host {
          } */

          * {
            --theme-container-font-style: var(--font-style-italic);
          }
        </style>
      `,
    ],
  ])
}
