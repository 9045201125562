const layoutMixin = ({ imports }) => () => (superClass) => {
  let { R, rxHelpers } = imports
  return class LayoutMixin extends superClass {
    constructor() {
      super()
      this.layout = 'flex-row'
    }
    firstUpdated() {
      super.firstUpdated()

      //The Layout property handles the main layout, i.e. flex or grid
      rxHelpers
        .combineLatest([this.layout$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(() => {
          if (R.split('-', this.layout)[0] == 'grid') {
            this.grid = true
            this.flex = false
            this.columns = R.split('-', this.layout)[1]
            this.direction = 'row'
          } else if (R.split('-', this.layout)[0] == 'flex') {
            this.flex = true
            this.grid = false
            this.direction = R.split('-', this.layout)[1]
          }

          this.requestUpdate()
        })
    }

    addGridClassNames(node) {
      if (node.col != undefined && node.span != undefined) {
        node.classList.add('col' + node.col + 'span' + node.span)
      }
    }

    prepareNodes(nodes) {
      if (super.prepareNodes) {
        super.prepareNodes(nodes)
      }

      nodes.forEach((node, i) => {
        this.addGridClassNames(node, nodes.length, i)
      })
    }
  }
}

export { layoutMixin as default }
