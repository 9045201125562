const initState = {
  models: [
    {
      label: 'home',
      value: 'home',
      id: 1,
    },
    {
      label: 'about',
      value: 'about',
      id: 2,
    },
    {
      label: 'films',
      value: 'films',
      id: 3,
    },
    {
      label: 'in production',
      value: 'in-production',
      id: 4,
    },
    {
      label: 'news',
      value: 'news',
      id: 5,
    },
  ],

  presentation: [
    {
      model_id: 1,
      id: 1,
      selected: true,
    },
    {
      model_id: 2,
      id: 2,
      selected: false,
    },
    {
      model_id: 3,
      id: 3,
      selected: false,
    },
    {
      model_id: 4,
      id: 4,
      selected: false,
    },
    {
      model_id: 5,
      id: 5,
      selected: false,
    },
  ],
}

export { initState }
