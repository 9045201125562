import Application from '../Application/Application'
import Repository from '../Application/Repository'
import register from '../Application/register'

let app = (async () => {
  let application = new Application()
  application = application.proxy
  application.imports = {}

  application.registerModule('libs', register.get('libs')(new Repository()))
  application.initializeModule('libs')

  Object.assign(application.imports, {
    _: application.libs.get('lodash').module(),
    check: application.libs.get('check').module(),
    classMap: application.libs.get('classMap').module(),
    firebase: application.libs.get('firebase').module(),
    installRouter: application.libs.get('installRouter').module(),
    lit: application.libs.get('lit-element').module(),
    litHtml: application.libs.get('lit-html').module(),
    logger: application.libs.get('logger').module(),
    maxBy: application.libs.get('maxBy').module(),
    PouchDB: application.libs.get('PouchDB').module(),
    R: application.libs.get('R').module(),
    // rxHelpers: application.libs.get('rxHelpers').module(),
    rxjs: application.libs.get('rxjs').module(),
    rxjsOperators: application.libs.get('rxjs/operators').module(),
    voca: application.libs.get('voca').module(),
  })

  Object.assign(application.imports, {
    BehaviorSubject: application.imports.rxjs.BehaviorSubject,
    combineLatest: application.imports.rxjs.combineLatest,
    concatAll: application.imports.rxjsOperators.concatAll,
    empty: application.imports.rxjs.empty,
    from: application.imports.rxjs.from,
    html: application.imports.lit.html,
    isObservable: application.imports.rxjs.isObservable,
    LitElement: application.imports.lit.LitElement,
    map: application.imports.rxjsOperators.map,
    merge: application.imports.rxjsOperators.merge,
    mergeMap: application.imports.rxjsOperators.mergeMap,
    of: application.imports.rxjs.of,
    render: application.imports.litHtml.render,
  })

  Object.assign(application.imports, {
    messenger: new application.imports.BehaviorSubject(),
  })

  application.registerModule('icons', register.get('icons')(new Repository()))
  application.initializeModule('icons')

  Object.assign(application.imports, {
    icons: new Map([
      ['flagiconcss', application.icons.get('flagiconcss').module()],
      ['fontawesome', application.icons.get('fontawesome').module()],
      ['ionicons', application.icons.get('ionicons').module()],
      ['materialdesign', application.icons.get('materialdesign').module()],
    ]),
  })

  application.registerModule('modules', register.get('modules')(new Repository()))
  application.initializeModule('modules')

  Object.assign(application.imports, {
    redux: new (application.modules.get('Redux').module())(),
    browserChecker: new (application.modules.get('BrowserChecker').module)(),
    rxHelpers: application.modules.get('RxHelpers').module(),
    SetOperations: application.modules.get('SetOperations').module(),
  })

  application.registerModule('mixins', register.get('mixins')(new Repository()))
  application.initializeModule('mixins')

  Object.assign(application.imports, {
    datapropertyMixin: application.mixins.get('datapropertyMixin').module(),
    eventMixin: application.mixins.get('eventMixin').module(),
    iconsMixin: application.mixins.get('iconsMixin').module(),
    layoutMixin: application.mixins.get('layoutMixin').module(),
    orderMixin: application.mixins.get('orderMixin').module(),
    rxMixin: application.mixins.get('rxMixin').module(),
    slotMixin: application.mixins.get('slotMixin').module(),
    themeMixin: application.mixins.get('themeMixin').module(),
  })

  Object.assign(application.imports, {
    baseMixin: application.mixins.get('baseMixin').module(),
  })

  Object.assign(application.imports, {
    inputitemMixin: application.mixins.get('inputitemMixin').module(),
    selectoritemMixin: application.mixins.get('selectoritemMixin').module(),
  })

  application.registerModule('dataProviders', register.get('dataProviders')(new Repository()))
  application.initializeModule('dataProviders')
  Object.assign(application.imports, {
    localStore: await application.dataProviders.get('reduxStoreMaker').module(),
    transactionalLocalStore: await application.dataProviders.get('transactionalReduxStoreMaker').module(),
  })

  Object.assign(application.imports, {
    Router: application.modules.get('Router').module(),
  })

  application.registerModule('dispatchStrategies', register.get('dispatchStrategies')(new Repository()))
  application.initializeModule('dispatchStrategies')
  Object.assign(application.imports, {
    dispatchStrategies: application.dispatchStrategies,
  })

  application.registerModule('fetchStrategies', register.get('fetchStrategies')(new Repository()))
  application.initializeModule('fetchStrategies')
  Object.assign(application.imports, {
    fetchStrategies: application.fetchStrategies,
  })

  application.registerModule('queryStrategies', register.get('queryStrategies')(new Repository()))
  application.initializeModule('queryStrategies')
  Object.assign(application.imports, {
    queryStrategies: application.queryStrategies,
  })

  application.registerModule('renderStrategies', register.get('renderStrategies')(new Repository()))
  application.initializeModule('renderStrategies')
  Object.assign(application.imports, {
    renderStrategies: application.renderStrategies,
  })

  application.registerModule('slotStrategies', register.get('slotStrategies')(new Repository()))
  application.initializeModule('slotStrategies')
  Object.assign(application.imports, {
    slotStrategies: application.slotStrategies,
  })

  application.registerModule('updateItemsStrategies', register.get('updateItemsStrategies')(new Repository()))
  application.initializeModule('updateItemsStrategies')
  Object.assign(application.imports, {
    updateItemsStrategies: application.updateItemsStrategies,
  })

  application.registerModule('css', register.get('css')(new Repository()))
  application.initializeModule('css')
  Object.assign(application.imports, {
    baseCss: application.css.get('baseCss').module(),
    gridCss: application.css.get('gridCss').module(),
  })

  application.registerModule('themes', register.get('themes')(new Repository()))
  application.initializeModule('themes')

  Object.assign(application.imports, {
    themes: application.themes,
  })

  application.registerModule('components', register.get('components')(new Repository()))
  application.initializeModule('components')

  localStorage.setItem('state', JSON.stringify(application.imports.transactionalLocalStore.getState()))

  return application
})()
export { app as default }
