// Adds styles to themeStyles. ThemeStyles always start with "bare"-theme style and each added theme style overrides the previous

//No need for more changes

const themeMixin = ({ imports }) => () => (superClass) => {
  let { html, R, rxHelpers } = imports
  return class ThemeMixin extends superClass {
    constructor() {
      super()
      this.theme = 'bare'
    }
    firstUpdated() {
      super.firstUpdated()
      rxHelpers
        .combineLatest([this.theme$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(() => {
          if (this.themes) {
            this.themeStyles = [
              this.themes.get('bare'),
              ...R.split(' ', this.theme || 'bare')
                .filter((theme) => {
                  return theme != 'bare'
                })
                .map((theme) => {
                  return this.themes.get(theme)
                })
                .filter((themeStyle) => {
                  return themeStyle != undefined
                }),
            ]
          } else {
            console.log('No theme applied for: ', this.localName)
            this.themeStyles = html` <style></style> `
          }
          this.requestUpdate()
        })

      this.requestUpdate()
    }
  }
}

export { themeMixin as default }
