export default ({ imports }) => () => {
  let { html } = imports
  return new Map([
    [
      'bare',
      html`
        <style>
          * {
          }
        </style>
      `,
    ],
  ])
}
