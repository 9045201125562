export default (imports) => {
  let { baseMixin, componentThemes, classMap, html, LitElement, transactionalLocalStore } = imports
  let props = () => []

  class BfInproductionPage extends baseMixin(props, LitElement) {
    constructor() {
      super()
      this.childTags = ['*']
      this.layout = 'flex-column'
    }

    get themes() {
      return componentThemes
    }

    async handleItemChanged(component, e, i) {
      e.stopPropagation()
      component.dispatchStrategy.changed({
        dispatcher: component,
        body: await component.queryStrategy.changed({ e, i, component }),
      })
      component.requestUpdate()
    }

    handleSlotchange(e) {
      this.nodes = this.getSlottedNodes(this.childTags, e)

      this.prepareNodes(this.nodes)

      // this.nodes.forEach((node, i) => {
      //   if (node.unsubscribe) {
      //     node.unsubscribe()
      //   }
      //   node.unsubscribe = node.eventHandlerRegistrar(node, 'checked-changed-event', (e) => {
      //     this.handleItemChanged(this, e, i)
      //   })
      // })
    }

    render() {
      return html`
        ${this.themeStyles} ${this.renderSlotsCSS()}
        <style>
          /* iPhone 12 Pro Max Vertical */
          @media screen and (min-width: 0px) and (max-width: 428px) {
            :host {
              --header-height: 100px;
              --footer-height: 288px;
            }

            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Vertical */
          @media screen and (min-width: 429px) and (max-width: 810px) {
            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Vertical */
          @media screen and (min-width: 811px) and (max-width: 1024px) {
            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1025px) and (max-width: 1366px) {
            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }

            .main {
              flex: 0 0 auto;
            }
          }

          /* Wider then iPad Pro 12.9 Horizontal */
          @media screen and (min-width: 1367px) {
            .container {
              display: flex;
              padding-bottom: 0px;
              padding-top: 76px;
              flex-direction: column;
            }

            .main {
              flex: 0 0 auto;
            }
          }
        </style>
        <div class=${classMap({ container: true })}>
          <bf-inproduction-itemgroup
            class=${classMap({ main: true })}
            .itemsdatasource=${{
              store: transactionalLocalStore,
              models: 'models',
              presentations: 'presentation',
              slice: 'bfFilms',
            }}
            fetchStrategyName="slice"
            renderItemsStrategyName="bf-in-production-item"
          ></bf-inproduction-itemgroup>
        </div>
      `
    }
  }

  customElements.define(`bf-inproduction-page`, BfInproductionPage)
}
