// Adds styles to iconStyles. IconStyles always start with "bare"-icon style and each added icon style overrides the previous

//No need for more changes

const iconsMixin = ({ imports }) => () => (superClass) => {
  let { html, icons, R, rxHelpers } = imports
  return class IconsMixin extends superClass {
    firstUpdated() {
      super.firstUpdated()
      rxHelpers
        .combineLatest([this.icon$])
        .pipe(rxHelpers.removeUndefinedElements())
        .subscribe(() => {
          if (icons) {
            this.iconStyles = [
              icons.get('bare'),
              ...R.split(' ', this.icon || 'bare')
                .filter((icon) => {
                  return icon != 'bare'
                })
                .map((icon) => {
                  return icons.get(icon)
                })
                .filter((iconStyle) => {
                  return iconStyle != undefined
                }),
            ]
          } else {
            // console.log('No icons applied for: ', this.localName)
            this.iconStyles = html` <style></style> `
          }

          this.requestUpdate()
        })

      this.requestUpdate()
    }
  }
}

export { iconsMixin as default }
