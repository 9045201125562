import customerApp from '../themes/customer-app-theme'
import bfAboutPage from '../themes/bf-about-page-theme'
import bfDescription from '../themes/bf-description-theme'
import bfFilmPage from '../themes/bf-film-page-theme'
import bfFilmsItem from '../themes/bf-films-item-theme'
import bfFilmsItemgroup from '../themes/bf-films-itemgroup-theme'
import bfFilmsPage from '../themes/bf-films-page-theme'
import bfFooter from '../themes/bf-footer-theme'
import bfHeader from '../themes/bf-header-theme'
import bfHomePage from '../themes/bf-home-page-theme'
import bfInproductionItem from '../themes/bf-inproduction-item-theme'
import bfInproductionBItem from '../themes/bf-inproduction-b-item-theme'
import bfInproductionItemgroup from '../themes/bf-inproduction-itemgroup-theme'
import bfInproductionPage from '../themes/bf-inproduction-page-theme'
import bfNewsItem from '../themes/bf-news-item-theme'
import bfNewsItemgroup from '../themes/bf-news-itemgroup-theme'
import bfNewsPage from '../themes/bf-news-page-theme'

let map = new Map([
  ['customer-app', customerApp],
  ['bf-about-page', bfAboutPage],
  ['bf-description', bfDescription],
  ['bf-film-page', bfFilmPage],
  ['bf-films-item', bfFilmsItem],
  ['bf-films-itemgroup', bfFilmsItemgroup],
  ['bf-films-page', bfFilmsPage],
  ['bf-footer', bfFooter],
  ['bf-header', bfHeader],
  ['bf-home-page', bfHomePage],
  ['bf-inproduction-item', bfInproductionItem],
  ['bf-inproduction-b-item', bfInproductionBItem],
  ['bf-inproduction-itemgroup', bfInproductionItemgroup],
  ['bf-inproduction-page', bfInproductionPage],
  ['bf-news-item', bfNewsItem],
  ['bf-news-itemgroup', bfNewsItemgroup],
  ['bf-news-page', bfNewsPage],
])

export { map as default }
