const initState = {
  models: [
    {
      label: 'men who sing',
      status: ['ready'],
      description: [
        ['Year', 2021],
        ['Duration', '76 min'],
        ['Director', 'Dylan Williams'],
        ['Producer', 'Dylan Wiliams'],
        ['Editor', 'Ben Stark / Magnus Svensson / Dylan Williams'],
        ['Music', 'Scott Shields'],
        ['With Support From', 'SVT / BFIDoc Society / FfCW / S4C International / Creative Europe – MEDIA Programme of the European Union'],
        ['In co-production with', 'Cwmni Da'],
      ],
      paragraphs: [
        'This humorous and melancholic portrait of a Male Voice Choir begins when the filmmakers’ father, widower Ed, 90, sells the family home and begins to arrange his own funeral. The only part of life that he still clings on to however is Tuesday night practice with his beloved choir, to which he’s been a member for 70 years.',
        'The Choir however is in trouble too. With an average age of 74, and with members dying every month action must be taken. The resulting membership drive and participation in a competition for the first time in 20 years, offers a poignant insight to a group of men who share a special bond.',
      ],
      posterimage: { source: './images/backflip/posters/men_who_sing_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/iVILMn1zFmk',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/men_who_sing.mp4',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 1,
    },
    {
      label: 'mr director',
      status: ['ready'],
      description: [
        ['Year', 2020],
        ['Duration', '4 min'],
        ['Director', 'Dylan Wiliams'],
        ['Producer', 'Dylan Wiliams'],
        ['Editor', 'Dylan Wiliams'],
        ['With Support From', 'SVT'],
      ],
      paragraphs: [
        'When Gustav and Jesper met in high school, they had one thing in common - a love of horror and zombie movies, which were so bad that they became entertainment.  After producing a dozen zero budget films, they suddenly manage to sell a script to a financier and with a budget of € 2000, they can now realize their dream project ‘The Room Below’',
      ],
      posterimage: { source: './images/backflip/posters/mr_director_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/mr_director.mp4',
        // image: './images/backflip/previews/mr_director.jpg',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/mr_director.mp4',
        // image: './images/backflip/previews/mr_director.jpg',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 2,
    },
    {
      label: 'the heart is a drum',
      status: ['ready'],
      description: [
        ['Year', 2019],
        ['Duration', '74 min'],
        ['Director', 'Jacob Frössen'],
        ['Producer', 'Jacob Frössen'],
        ['Editor', 'Jacob Frössen / Britta Norell / Michel Wenzer / Dylan Williams'],
        ['Music', 'Jacob Frössén / Love Martinsen'],
        ['Festivals', 'CPH:Dox; IFFR – Rotterdam amongst others'],
        ['With Support From', 'SVT / ARTE / Film I Väst'],
        ['In co-production with', 'Epigram'],
        ['Distribution', 'Tri-ART'],
      ],
      paragraphs: [
        'Motorik. The insistent drum beat that became the beating heart of kraut rock. In the spring of 1971, drummer Klaus Dinger is left heartbroken after the break up with a Swedish girl, which leads to the birth of the drumbeat and the legendary band NEU! The band that, according to Iggy Pop, "was punk before punk existed". In interviews with, among others, Stephen Morris from Joy Division / New Order, Bobby Gillespie from Primal Scream and Wolfgang Flür from Kraftwerk, a picture is drawn of how the monotonous 4/4-beat changed music history. And of the sunken love story that inspired Dinger.',
      ],
      posterimage: { source: './images/backflip/posters/the_heart_is_a_drum_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/nH3Zgfw6jVw',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/the_heart_is_a_drum.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 3,
    },
    {
      label: 'last breath',
      status: ['ready'],
      description: [
        ['Year', 2019],
        ['Duration', '76 min'],
        ['Director', 'Richard da Costa / Alex Parkinson'],
        ['Producer', 'Al Morrow / Stewart Le Macheral / Angus Lamont / Dylan Williams'],
        ['Editor', 'Sam Rogers'],
        ['Music', 'Paul Leonard Morgan'],
        ['Festivals', 'Glasgow FF, Stiges Intl FF, Hot Docs'],
        ['Distribution', 'BBC, Arte, Netflix'],
      ],
      paragraphs: [
        'A spellbinding documentary about the rescue of Chris Lemons, a commercial diver who was left stranded mid-dive in the North Sea with 5 minutes of oxygen in his emergency tank.',
        'A commercial diver becomes trapped on the ocean floor with dwindling oxygen and little hope of a timely rescue, so he tries to save himself.',
      ],
      posterimage: { source: './images/backflip/posters/last_breath_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/mSJL57Gq2OQ',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/last_breath.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 4,
    },
    {
      label: 'swimming with men',
      status: ['ready'],
      description: [
        ['Year', 2018],
        ['Duration', '76 min'],
        ['Director', 'Oliver Parker'],
        ['Producer', 'Stewart Le Macheral / Anna Mohr Pietsch / Maggie Montieth'],
        ['Editor', 'Rob Brydon, Rupert Graves, Jim Carter, Charlotte Riley, Danny Mays'],
        ['Executive Producer', 'Al Morrow / Jonny Persey / Dylan Williams'],
        ['Distribution', 'IFC Films, Alamode, Kino Films'],
      ],
      paragraphs: [
        'A man who is suffering a mid-life crisis finds new meaning in his life as part of an all-male, middle-aged, amateur synchronised swimming team. Swimming with Men is a British comedy film starring Rob Brydon, Jane Horrocks, Rupert Graves, Daniel Mays, Thomas Turgoose, Jim Carter, Adeel Akhtar and Charlotte Riley. Directed by Oliver Parker, the script was based the highly successful 2010 Swedish documentary Men Who Swim',
      ],
      posterimage: { source: './images/backflip/posters/swimming_with_men_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/f495YKIfuaw',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/swimming_with_men.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 5,
    },
    {
      label: 'exit',
      status: ['ready'],
      description: [
        ['Year', 2018],
        ['Duration', '83 min'],
        ['Director', 'Karen Winther'],
        ['Producer', 'Eirin Gjorv'],
        ['Music', 'Michael Wenzer'],
        ['Festivals', 'CPH:Dox'],
        ['With Support From', 'NRK / ARTE / Al Jazeera '],
      ],
      paragraphs: [
        'How can you belong to a group that believes firmly in an idea, and what makes you then suddenly change your mind? Director Karen Winther tried that herself, and now she is finding other previous violent extremists to hear their story about leaving the violent and extremist community behind. In Exit, the director Karen Winther goes sourcing for answers to the question: What was the ‘wake-up call’ – that made her and a group of previous violent extremists leave violence and extremism behind?',
        'Through intimate conversations with former violent extremists from Germany, France, Denmark, and the US she asks: What was it that made us alter the course of our lives? Karen, living in Norway, will meet Angela in the US and Manuel and Ingo in Germany, all of whom are living in hiding due to their dangerous pasts. She will also meet with Søren, who has a violent leftwing-extremist background. And she will meet David, a former jihadist, who participated in one of the first homegrown Islamist terrorist attacks in France.',
        'Throughout this journey, Karen moves closer to remember her own wake-up call. Reflections on her extremist past and way she managed to go out of extremism will form an emotional and psychological counterpoint with the stories of the people she meets in her travels.',
      ],
      posterimage: { source: './images/backflip/posters/exit_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/S2SGuD-1dEI',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/exit.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 6,
    },
    {
      label: 'the rainforest robbery',
      status: ['ready'],
      description: [
        ['Year', 2017],
        ['Duration', '5 x 15 min'],
        ['Director', 'Dylan Wiliams'],
        ['Producer', 'Erik Pauser / Dylan Wiliams'],
        ['Editor', 'Dylan Wiliams'],
        ['With Support From', 'ZDF'],
        ['In co-production with', 'Boekamp & Kriegsheim '],
      ],
      paragraphs: [
        'Intimate story telling the background story about the making of the Documentary film The Borneo Case. The series follows the filmmakers closely as they are chased by secret police, havet o deal with legal threats and many of the stories that arose out of the making of a film that showed how billions of dollars are being moneylaundered every year by some of the largest banks in the World.',
      ],
      posterimage: { source: './images/backflip/posters/the_rainforest_robbery_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/rainforest_robbery.mp4',
        // image: './images/backflip/previews/rainforest_robbery.jpg',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/rainforest_robbery.mp4',
        // image: './images/backflip/previews/rainforest_robbery.jpg',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 7,
    },
    {
      label: 'the borneo case',
      status: ['ready'],
      description: [
        ['Year', 2016],
        ['Duration', '78 min'],
        ['Director', 'Erik Pauser / Dylan Williams'],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['Editor', 'Simon Barker / Emil Engerdahl / Dylan Williams '],
        ['Music', 'Scott Shields'],
        ['Festivals', 'IDFA'],
        [
          'With Support From',
          'SVT / SFI / DR2 / DFI / NRK / NFTVF / IKON / Creative Europe – MEDIA Programme of the European Union / ZDF-Arte / Influence Film Foundation/ Fritt Ord / BERTHA / Britdoc / Film Cymru Wales / PMA World view / Transparency International',
        ],
        ['In co-production with', 'Boekamp & Kriegsheim / Red Rebel Films / Sant & Usant'],
        ['Distribution', 'Tri-ART'],
      ],
      paragraphs: [
        'In The Borneo Case, documentary filmmakers Erik Pauser and Dylan Williams spend five years intimately following the trail of an unlikely group of activists whose aim is to investigate how profits from the illegal logging that has annihilated more than 90% of the Malaysian Borneo Rainforest, have been money laundered into property portfolios all around the world.',
        'The group, made up of an exiled tribesman, a historian, an investigative journalist and a flamboyant DJ overcome death threats and intimidation in their efforts to unravel what has been dubbed “the Greatest Environmental Crime in History” (ex British Prime Minister Gordon Brown).',
      ],
      posterimage: { source: './images/backflip/posters/the_borneo_case_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/4wHOIZ0sS_g',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/the_borneo_case.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 8,
    },
    {
      label: 'storyboard p',
      status: ['ready'],
      description: [
        ['Year', 2015],
        ['Duration', '64 min'],
        ['Director', 'Matt D’Arcy'],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['Editor', 'Matt D’Arcy'],
        ['Festivals', 'Sheff Doc'],
        ['With Support From', 'SVT / SFI / EU Media Desk'],
      ],
      paragraphs: [
        'Storyboard P glides smoothly through the snow, carving shapes with his slender limbs. Though he has been sleeping rough in Stockholm for a while now, he is still flush with self-belief. “My vision takes it to an art level,” the Brooklyn native asserts. Will the talented dancer finally get his break? Director Matthew D’Arcy chronicles Storyboard’s early steps towards international stardom.',
      ],
      posterimage: { source: './images/backflip/posters/storyboard_p_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/4AIWzLfmWio',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/storyboard_p.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 9,
    },
    {
      label: 'palmes hemliga agent',
      status: ['ready'],
      description: [
        ['Year', 2015],
        ['Duration', '58 min'],
        ['Director', 'Erik Pauser / Dylan Williams / Matt D’Arcy'],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['Editor', 'Matt D’Arcy / Dylan '],
        ['With Support From', 'SVT / SFI / SABC'],
      ],
      paragraphs: [
        'At the height of the cold war a struggle broke out between Governments from all over the world as to which position to take about the system of apartheid in South Africa. Leading the fight was Olof Palmes’ Swedish Government, which covertly funneled over US$ 1 billion (corresponding to the present day rate) to the resistance movement. This money was given without the knowledge of either the Parliament or the Swedish populace. At the center of the net in South Africa was a Swedish diplomat called Birgitta Karlström Dorph.',
        'Meanwhile at the UN the Swedes with their Scandinavian counterparts attempted to win the argument for economic sanctions. This led to bitter arguments which saw Palme leading the fight against the Reagen and Thatcher administrations.',
      ],
      posterimage: { source: './images/backflip/posters/palmes_hemliga_agent_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/_4Ah8HCRcrQ',
        // image: './images/backflip/previews/palmes_hemliga_agent.jpg',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/palmes_hemliga_agent.mp4',
        // image: './images/backflip/previews/palmes_hemliga_agent.jpg',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 10,
    },
    {
      label: 'the machine',
      status: ['ready'],
      description: [
        ['Year', 2013],
        ['Duration', '58 min'],
        ['Director', 'Erik Pauser'],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['DoP', 'Lars Tunbjörk'],
        ['With Support From', 'SVT / SFI'],
      ],
      paragraphs: [
        'A few years ago Johan Jönson wrote the acclaimed book ‘Efter arbetsschema’. A 800-page tomb of poetic confession, diary and contemporary reflection. Since then, he has written two poetry books, both of which were over 1000 pages each, and has been called one of Sweden’s most important working-class poets. Johan Jonson writes extremely frankly about himself as well about the people he meets. Jönson does not hold back from communicating thoughts of violence and self-loathing that pass through his brain. He has been described as having ‘a twisted and brutal sense of humor’.',
        'Johan Jönson won Aftonbladet’s Literature Prize in 2008 and was nominated for the Nordic Council Literature Prize in 2009. His subsequent book "Livsdikt"(2010) received considerable attention and was nominated for the prestigious August Prize. In January 2012, he published his latest book – the 1200 pages long "med.bort.in".',
      ],
      posterimage: { source: './images/backflip/posters/the_machine_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/the_machine.mp4',
        // image: './images/backflip/previews/the_machine.jpg',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/the_machine.mp4',
        // image: './images/backflip/previews/the_machine.jpg',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 11,
    },
    {
      label: 'infected',
      status: ['ready'],
      description: [
        ['Year', 2012],
        ['Duration', '72 min'],
        ['Director', 'Dylan Williams'],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['Editor', 'Dylan Williams '],
        ['Music', ' Linus Wiklund'],
        ['With Support From', 'SVT / SFI'],
      ],
      paragraphs: [
        'As the HIV epidemic spread around the world in the early 1980′s panic broke out. In Sweden alone newspapers predicted that over a million people would die before the year 2000. HIV patients were often kept under lock and key as authorities struggled to know what to do and the public in general believed anything they heard. Using unique and often very telling archive material, this film will weave the compelling and emotive stories of people whose lives were changed forever by the "virus from hell".',
        'As we look into the not so distant past and observe the reflex actions of our society when under threat, this film asks what we have learnt from our experience and whether it will allow us to deal with the next crisis differently?',
      ],
      posterimage: { source: './images/backflip/posters/infected_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/infected.mp4',
        // image: './images/backflip/previews/infected.jpg',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/infected.mp4',
        // image: './images/backflip/previews/infected.jpg',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 12,
    },
    {
      label: 'once there was love',
      status: ['ready'],
      description: [
        ['Year', 2013],
        ['Duration', '58 min'],
        ['Director', 'VHåkan Pieniowski / Kåge Jönsson'],
        ['Producer', 'Dylan Williams'],
        ['Editor', 'Håkan Pieniowski'],
        ['With Support From', 'SVT / SFI / Creative MEDIA Programme of the European Union'],
      ],
      paragraphs: [
        'Once There Was Love is a powerful dark biopic of a one-time middle class Russian family whose life is slowly coming apart at the edges. Covering the 12 years since the birth of baby Nadja, this follow up to the Prix D’Italia winner ‘I Love you Natasja’ offers a dramatic and uniquely intimate portrait of an incredible modern day odyssey undertaken by a family in a small town in Russia.',
        'Nominated Prix D’Europa',
        'Special mention – Prix D´Italia',
        'Best SVT Documentary – SVT Ikaros Price',
        'Best film – Vera Film Festival',
        'Estonian People´s Award – Pärnu International Film Festival',
        'Dragon award nominee – Göteborg Film Festival',
        'BHonorable mention – Tarkovsky Film Festival, Ivanovo',
        'Best Documentary – Art Doc Fest, Moscow',
      ],
      posterimage: { source: './images/backflip/posters/once_there_was_love_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/once_there_was_love.mp4',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/once_there_was_love.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 13,
    },
    {
      label: 'maneuvers in the dark',
      status: ['ready'],
      description: [
        ['Year', 2012],
        ['Duration', '58 min'],
        ['Director', 'Maria Ramström'],
        ['Producer', 'Erik Pauser / Dylan Williams / Peter Wintonick'],
        ['Editor', 'Dominika Daubenuchel'],
        ['Music', 'Daniel Fagerström'],
        ['Festivals', 'IDFA'],
        ['With Support From', 'SVT / SFI / DR2 / Creative MEDIA Programme of the European Union'],

        ['Distribution', 'TBC'],
      ],
      paragraphs: [
        'Maneuvers in the Dark is the story of how three young Swedish entrepreneurs manage to sneak through the backdoor of North Korea in an attempt to produce jeans in the country, and of the spiraling repercussions that follow as they begin to trade with the worlds most sealed dictatorship.',
        'Directors statement: “Trade as a means of creating democracy, sounds like a good idea. But is my purchase of a garment produced in a dictatorship ethically correct? Maybe. One thing is for sure, many more and substantially larger companies than those we meet in this film guarantee just that ­ ethical production in non-democratic countries. To look at the implications of this is the basis for this film about a production line in a closed dictatorship”.',
        'Maria Ramström Stockholm January 2012',
      ],
      posterimage: { source: './images/backflip/posters/maneuvers_in_the_dark_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/maneuvers_in_the_dark.mp4',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/maneuvers_in_the_dark.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 14,
    },
    {
      label: 'men who swim',
      status: ['ready'],
      description: [
        ['Year', 2010],
        ['Duration', '74 min'],
        ['Director', 'Dylan Williams'],
        ['Producer', 'Al Morrow / Stewart Le Marechal / Erik Pauser'],
        ['Editor', 'Jerry Rothwell / Dylan Williams '],
        ['Music', 'Scott Shields'],
        ['Festivals', 'IDFA, Silverdocs 50+ Festivals'],
        ['With Support From', 'SVT / ZDF-ARTE / BBC Storyville / VPRO / YLE/ NFTVF / Yes-Tv / Creative Europe – MEDIA Programme of the European Union'],
      ],
      paragraphs: [
        'Men Who Swim is a humorous and poignant look at a group of middle aged men who have found unlikely success as members of Sweden’s all male synchronised swimming team. What began as a weekly escape from the daily grind of work and family responsibilities, soon evolved into a more serious commitment. Inspired by classical Esther Williams’ techniques from the 1950s, this group of train drivers and meat buyers, of archivists and teachers soon became passionate exponents of the sport. Now, the team has been unexpectedly invited to next years Male World Synchronised Swimming Championships in Milan, and between now and April 2009 they will need to prepare a winning 12 minute routine that will be marked on artistic and technical ability.',
        'Nominated for the Grierson Award ­ Most Entertaining Documentary',
        'Audience prize – Supetar Film Festival, Croatia',
        'Audience prize – AFI-Silverdocs, Washington',
        'Best sound – Los Angeles International Film Festival',
      ],
      posterimage: { source: './images/backflip/posters/men_who_swim_cropped.png', pattern: 'proportional-height' },
      video: {
        source: 'https://www.youtube.com/embed/QU6z9oVvfKo',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        muted: false,
        controls: true,
        provider: 'youtube',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/men_who_swim.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 15,
    },
    {
      label: 'the face of the enemy',
      status: ['ready'],
      description: [
        ['Year', 2009],
        ['Duration', '76 min'],
        ['Director', 'Erik Pauser '],
        ['Producer', 'Erik Pauser / Dylan Williams'],
        ['Editor', 'Clas Lindberg'],
        ['Music', 'Jean Louis Huhta'],
        ['Festivals', 'IDFA'],
        ['With Support From', 'SVT/ SFI/ DR2/ NRK'],
      ],
      paragraphs: [
        'Over five million Vietnamese died in what one side calls the American War and the other side calls the Vietnam War. A war so brutal, that it has been described as their ‘own’ Third World War by the Vietnamese. The core of this film is to investigate the hearts and minds of the Vietnamese who fought in that war. How did they sustain their morale in the face of such overwhelming odds? What did they believe in? How do they look at the war now? The film premiered in competition at IDFA 2009 . The Face of the Enemy and the adjoining installation were shown first as a soloshow at Norrköping art museum 2010 and in Gothenburg at Angered cultural house in the beginning of 2011.',
      ],
      posterimage: { source: './images/backflip/posters/the_face_of_the_enemy_cropped.png', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/face_of_the_enemy.mp4',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/face_of_the_enemy.mp4',
        image: '',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 16,
    },
    {
      label: 'reserved for george',
      status: ['ready'],
      description: [
        ['Year', 2006],
        ['Duration', '16 min'],
        ['Director', 'Jonas Beckman / Dylan Williams'],
        ['Producer', 'Jonas Beckman / Dylan Williams'],
        ['Editor', 'Dylan Williams'],
        ['With Support From', 'SVT'],
      ],
      paragraphs: [
        'For many years, the fine old gentleman sitting quietly each and every day at his table in the corner of the restaurant in Östra Station in Stockholm was unknown. It took over 60 years before anyone knew his name. And then one day he did something extraordinary that was to save the restaurant that had fed through his life. A story that launched this hermit onto the front page of all the National papers.  ',
      ],
      posterimage: { source: './images/backflip/posters/reserved_for_george_cropped.png', pattern: 'proportional-height' },
      id: 17,
    },
    {
      label: 'the last great event',
      status: ['in-production'],
      description: [
        ['Director', 'P.A Carter / Dylan Williams'],
        ['Producer', 'P.A Carter / Sam Taylor / Dylan Williams'],
        ['Editor', 'Ben Stark'],
        ['With Development Support From', 'SVT / SFI'],
        ['In co-production', 'Precipice Media'],
        ['Distribution', 'TBC'],
      ],
      paragraphs: [
        'This is the untold and scarcely believable story of how a group of youngsters -- 23 year old Ray Foulk and his younger brothers Ronnie and Bill, along with close friend Rikki Farr -- all complete amateurs with zero experience -- managed to lure the greatest names in music to come to a sleepy little island off the coast of England that was their home -- and pull off the biggest rock festival in history. ',
      ],
      posterimage: { source: './images/backflip/posters/last_great_event.jpg', pattern: 'proportional-height' },
      id: 18,
    },
    {
      label: 'rain. the untold story',
      status: ['in-production'],
      description: [
        ['Duration', '3 X 60 mins'],
        ['Director', 'Dylan Williams'],
        ['Producer', 'Euros Wyn / Mairead Ni Thréinir'],
        ['Executive Producer', 'Llion Iwan'],
        ['Editor', 'Jiwlian Tomos'],
        ['With Support From', 'LIC China / BBC'],
      ],
      paragraphs: [
        'A rich and sweeping series filmed in a dozen countries all over the World that looks at how our dependency on Rain and freshwater has defined cultures and societies through the ages and how the increasing crisis of availability of water is driving the need to harness and increase rain as a means of survival',
      ],
      posterimage: { source: './images/backflip/posters/rain.jpg', pattern: 'proportional-height' },
      id: 19,
    },
    {
      label: 'behind the swedish model',
      status: ['ready'],
      description: [
        ['Year', 2021],
        ['Duration', '76 min'],
        ['Director and Producer', 'Viktor Nordenskiöld'],
        ['Producer', 'Dylan Williams'],
        ['Editor', 'Tell Aulin / Simon Carlgren / Nils Nouchi  / Dylan Williams / Viktor Nordenskiöld'],
        ['Music', 'Martin Landquist'],
        ['With Support From', 'SVT/ SFI /NFI / NFTVF / NRK / Dr2 / Fritt Ord'],
        ['In co-production with', 'Freetown Films, Sant og Usant'],
      ],
      paragraphs: [
        'A unique behind the scenes documentary following Sweden’s World famous State Epidemologist Anders Tegnell and Health Minister Lena Hallengren as they attempt to meet the pandemic by following a controversial no-formal-lockdown-strategy. As the World enters lockdown and people are gripped by panic they must navigate a new reality  and face a barrage of criticim, hate and even worship with every decision they make. ',
      ],
      posterimage: { source: './images/backflip/posters/behind_the_swedish_model_poster.jpeg', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/behind_the_swedish_model.mp4',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/behind_the_swedish_model.mp4',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 20,
    },
    {
      label: 'born to fly',
      status: ['ready'],
      description: [
        ['Year', 2022],
        ['Duration', '1X90 min'],
        ['Director and Producer', 'Brennan Robideaux'],
        ['Producer', 'Dylan Williams / William Ramsey'],
        ['Editor', 'Nils Nouchi / Brennan Robideaux'],
        ['Music', 'Joaquin Garcia'],
        ['With Support From', 'Red Bull Media House / SVT / SFI'],
      ],
      paragraphs: [
        'Born to Fly tells the story of Mondo Duplantis and his rise to fame from child prodigy to the greatest Pole Vaulter of all time. From breaking the world record in 2020 to crowning himself the Olympic Gold Medalist in the following year, he is now the new face of track and field at the young age of 21. At the same time, this intimate documentary is a coming of age tale that sees a young man focused on being the best, struggle to come to terms with the challenges of a whole new world.',
      ],
      posterimage: { source: './images/backflip/posters/born_to_fly_poster.jpeg', pattern: 'proportional-height' },
      video: {
        source: './videos/backflip/trailers/born_to_fly.mp4',
        image: '',
        pattern: 'proportional-height',
        autoplay: false,
        controls: true,
        muted: false,
        provider: 'self',
      },
      backgroundvideo: {
        source: './videos/backflip/backgrounds/born_to_fly.mp4',
        pattern: 'proportional-height-or-fullscreen-90',
        provider: 'self',
        autoplay: true,
        muted: true,
        controls: false,
      },
      id: 21,
    },
  ],

  presentation: [
    {
      model_id: 21,
      id: 1,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 20,
      id: 2,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 1,
      id: 3,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 2,
      id: 4,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 3,
      id: 5,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 4,
      id: 6,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 5,
      id: 7,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 6,
      id: 8,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 7,
      id: 9,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 8,
      id: 10,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 9,
      id: 11,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 10,
      id: 12,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 11,
      id: 13,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 12,
      id: 14,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 13,
      id: 15,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 14,
      id: 16,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 15,
      id: 17,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 16,
      id: 18,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 17,
      id: 19,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 18,
      id: 20,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
    {
      model_id: 19,
      id: 21,
      theme: 'backflip-footer',
      labelFn: 'uppercase',
    },
  ],
}

export { initState }
