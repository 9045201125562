import baseMixin from 'basemixin'
import crudMixin from 'crudmixin'
import datapropertyMixin from 'datapropertymixin'
import dragMixin from 'dragmixin'
import eventMixin from 'eventmixin'
import iconsMixin from 'iconsmixin'
import inputitemMixin from 'inputitemmixin'
import layoutMixin from 'layoutmixin'
import orderMixin from 'ordermixin'
import rxMixin from 'rxmixin'
import selectoritemMixin from 'selectoritemmixin'
import slotMixin from 'slotmixin'
import themeMixin from 'thememixin'

let map = new Map([
  ['baseMixin', baseMixin],
  ['crudMixin', crudMixin],
  ['datapropertyMixin', datapropertyMixin],
  ['dragMixin', dragMixin],
  ['eventMixin', eventMixin],
  ['iconsMixin', iconsMixin],
  ['inputitemMixin', inputitemMixin],
  ['layoutMixin', layoutMixin],
  ['orderMixin', orderMixin],
  ['rxMixin', rxMixin],
  ['selectoritemMixin', selectoritemMixin],
  ['slotMixin', slotMixin],
  ['themeMixin', themeMixin],
])

export { map as default }
